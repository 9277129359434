<template>
  <b-modal
    id="user-change-password"
    size="lg"
    header-bg-variant="primary"
    header-text-variant="white"
    footer-bg-variant="light"
    footer-text-variant="white"
    body-bg-variant="light"
    body-class="modal-body-no-spacing"
    ok-only
    ok-variant="secondary"
    scrollable
    v-model="show"
    :title="$t('Change password')"
    :ok-title="$t('Close')"
  >
    <template v-slot:default>
      <b-card-body>
        <b-form>
          <b-row class="align-items-center my-1">
            <b-col sm="3">
              <label for="new-password">{{ $t("New password") }}</label>
            </b-col>
            <b-col sm="9">
              <b-input-group>
                <b-form-input
                  id="new-password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  @keyup.enter="update()"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    class="password-display-btn"
                    @click="showPassword = !showPassword"
                    tabindex="-1"
                  >
                    <font-awesome-icon
                      :icon="['far', 'eye-slash']"
                      v-if="showPassword"
                    />
                    <font-awesome-icon
                      :icon="['far', 'eye']"
                      v-if="!showPassword"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="align-items-center my-1">
            <b-col sm="3">
              <label for="verification-password">{{
                $t("Password verification")
              }}</label>
            </b-col>
            <b-col sm="9">
              <b-input-group>
                <b-form-input
                  id="verification-password"
                  :type="showVerification ? 'text' : 'password'"
                  v-model="verificationPassword"
                  @keyup.enter="update()"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    class="password-display-btn"
                    @click="showVerification = !showVerification"
                    tabindex="-1"
                  >
                    <font-awesome-icon
                      :icon="['far', 'eye-slash']"
                      v-if="showVerification"
                    />
                    <font-awesome-icon
                      :icon="['far', 'eye']"
                      v-if="!showVerification"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </template>
    <template v-slot:modal-footer>
      <div
        class="w-100 d-flex flex-row justify-content-between align-items-center"
      >
        <b-button variant="primary" @click="update()">{{
          $t("Save")
        }}</b-button>
        <b-button variant="secondary" class="float-right" @click="close()">
          {{ $t("Close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { UserApi } from "@/api";
import { endLoadingBar, startLoadingBar } from "@/store/helpers/global.helper";
import { User } from "@/model/user/user";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      show: false,
      username: "",
      password: "",
      showPassword: false,
      verificationPassword: "",
      showVerification: false,
      isAdmin: false,
    };
  },
  methods: {
    update() {
      if (!this.password || this.password !== this.verificationPassword) {
        this.error("Passwords do not match or zero password specified");
        return;
      }

      startLoadingBar();
      UserApi.updatePassordUser(this.getUser.id, this.password)
        .then(() => this.success("Password successfully changed"))
        .then(() => this.close())
        .catch(() => this.error("@wm_004"))
        .finally(() => endLoadingBar());
    },
    close() {
      this.password = "";
      this.verificationPassword = "";
      this.showPassword = false;
      this.showVerification = false;
      this.show = false;
    },
    success(messages: string): Promise<void> {
      return this.$store.dispatch("Notification/success", messages);
    },
    error(messages: string): Promise<void> {
      return this.$store.dispatch("Notification/error", messages);
    },
  },
  computed: {
    getUser(): User {
      return this.$store.getters["User/getUser"];
    },
  },
});
</script>

<style lang="scss">
.full-size {
  width: 100%;
}
</style>
