import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_input_group_append = _resolveComponent("b-input-group-append")!
  const _component_b_input_group = _resolveComponent("b-input-group")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_card_body = _resolveComponent("b-card-body")!
  const _component_site_spinner = _resolveComponent("site-spinner")!
  const _component_b_button_group = _resolveComponent("b-button-group")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    id: "projects",
    size: "lg",
    "header-bg-variant": "primary",
    "header-text-variant": "white",
    "footer-bg-variant": "light",
    "footer-text-variant": "white",
    "body-bg-variant": "light",
    "body-class": "modal-body-no-spacing",
    "ok-only": "",
    "ok-variant": "secondary",
    scrollable: "",
    "ok-title": _ctx.$t('Close'),
    title: _ctx.$t('Search Project')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_card_body, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { class: "my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, {
                    "label-cols-sm": "2",
                    "label-size": "sm",
                    "label-for": "filterInput",
                    class: "mb-0",
                    label: _ctx.$t('Search Project')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input_group, { size: "sm" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            modelValue: _ctx.filter,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
                            type: "search",
                            id: "filterInput"
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_b_input_group_append, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_button, {
                                disabled: !_ctx.filter,
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter = ''))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("Clear")), 1)
                                ], undefined, true),
                                _: 1
                              }, 8, ["disabled"])
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  }, 8, ["label"])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      }),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_site_spinner, {
            key: 0,
            class: "spinner"
          }))
        : _createCommentVNode("", true),
      (!_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_b_table, {
            key: 1,
            class: "mb-0",
            striped: "",
            "sticky-header": "",
            responsive: "sm",
            fields: _ctx.fields,
            items: _ctx.getProjects,
            filter: _ctx.filter
          }, {
            "cell(dateCreated)": _withCtx((row) => [
              _createTextVNode(_toDisplayString(row.item.dateCreated || _ctx.dateTime), 1)
            ]),
            "cell(action)": _withCtx((row) => [
              _createVNode(_component_b_button_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_button, {
                    variant: "primary",
                    size: "sm",
                    onClick: ($event: any) => (_ctx.onSelectProject(row.item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Select")), 1)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_b_button, {
                    variant: "primary",
                    size: "sm",
                    onClick: ($event: any) => (_ctx.onCopy(row.item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Copy")), 1)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_b_button, {
                    variant: "danger",
                    size: "sm",
                    onClick: ($event: any) => (_ctx.deleteProject(row.item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Delete")), 1)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["onClick"])
                ], undefined, true),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["fields", "items", "filter"]))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }, 8, ["ok-title", "title"]))
}