import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  errorNotify,
  warningNotify,
  startLoadingBar,
  endLoadingBar,
} from "@/store/helpers/global.helper";
import { RollerDriveBeltApi } from "@/api/index";
import { RollerDriveReport } from "@/model/roller-drive/roller-drive-report";

@Module({ namespaced: true })
class RollerDriveBeltReportStore extends VuexModule {
  public report: RollerDriveReport | undefined = undefined;

  get getParams(): RollerDriveReport | undefined {
    return this.report;
  }

  @Mutation
  public setParams(report: RollerDriveReport): void {
    this.report = report;
  }

  @Action
  public download() {
    if (this.report) {
      startLoadingBar();
      RollerDriveBeltApi.report(this.report)
        .catch((err) => errorNotify(err))
        .finally(() => endLoadingBar());
    } else {
      warningNotify("No data available");
    }
  }
}
export default RollerDriveBeltReportStore;
