import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_site_spinner = _resolveComponent("site-spinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_b_container = _resolveComponent("b-container")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_notification = _resolveComponent("notification")!
  const _component_user_management_modal = _resolveComponent("user-management-modal")!
  const _component_create_user_modal = _resolveComponent("create-user-modal")!
  const _component_user_settings_modal = _resolveComponent("user-settings-modal")!
  const _component_user_change_password_modal = _resolveComponent("user-change-password-modal")!
  const _component_projects_modal = _resolveComponent("projects-modal")!
  const _component_documentation_modal = _resolveComponent("documentation-modal")!
  const _component_factors_table_modal = _resolveComponent("factors-table-modal")!
  const _component_sort_of_tangential_drive_modal = _resolveComponent("sort-of-tangential-drive-modal")!
  const _component_pressure_roll_factor_modal = _resolveComponent("pressure-roll-factor-modal")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: _normalizeClass({ 'login-app': _ctx.$route.meta.isSignIn, 'is-loading': _ctx.isBarLoading })
  }, [
    (!_ctx.$route.meta.isSignIn)
      ? (_openBlock(), _createBlock(_component_Header, {
          key: 0,
          class: "sticky"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_b_container, {
      class: _normalizeClass(["header-margin py-4", { 'login-container': _ctx.$route.meta.isSignIn }]),
      fluid: "lg"
    }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_site_spinner, {
              key: 0,
              class: "spinner"
            }))
          : _createCommentVNode("", true),
        (!_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("main", {
              key: 1,
              class: _normalizeClass({ 'login-main': _ctx.$route.meta.isSignIn })
            }, [
              _createVNode(_component_router_view)
            ], 2))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Footer),
    _createVNode(_component_notification),
    (_ctx.getUser)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.getUser.isAdmin)
            ? (_openBlock(), _createBlock(_component_user_management_modal, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.getUser.isAdmin)
            ? (_openBlock(), _createBlock(_component_create_user_modal, { key: 1 }))
            : _createCommentVNode("", true),
          _createVNode(_component_user_settings_modal),
          _createVNode(_component_user_change_password_modal),
          _createVNode(_component_projects_modal),
          _createVNode(_component_documentation_modal),
          _createVNode(_component_factors_table_modal),
          _createVNode(_component_sort_of_tangential_drive_modal),
          _createVNode(_component_pressure_roll_factor_modal)
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}