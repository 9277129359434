<template>
  <b-modal
    id="pressure-roll-factor-modal"
    size="xl"
    header-bg-variant="primary"
    header-text-variant="white"
    footer-bg-variant="light"
    footer-text-variant="white"
    body-bg-variant="white"
    body-class="modal-body-no-spacing"
    ok-only
    ok-variant="secondary"
    scrollable
    v-model="show"
    :title="$t('Sketches')"
    :ok-title="$t('Close')"
  >
    <b-card-body v-if="getParams && getParams.i">
      <b-row class="align-self-stretch" cols="1" align-v="stretch">
        <b-col
          class="align-items-stretch p-2"
          v-for="value in getParams.i.allowedValues"
          :key="value"
        >
          <b-card
            class="h-100"
            align="center"
            img-bottom
            :header-bg-variant="
              getParams.i.value === value ? 'primary' : 'light'
            "
            :header-text-variant="
              getParams.i.value === value ? 'white' : 'black'
            "
            :border-variant="getParams.i.value === value ? 'primary' : 'light'"
            @click="
              inputSelect(value, 'i');
              show = false;
            "
          >
            <template #header>
              <font-awesome-icon
                class="check-card-symbol"
                size="lg"
                v-if="getParams.i.value !== value"
                :icon="['far', 'circle']"
              />
              <font-awesome-icon
                class="check-card-symbol"
                size="lg"
                v-if="getParams.i.value === value"
                :icon="['far', 'check-circle']"
              />
              PRF {{ value }}
            </template>
            <b-card-text>
              <img
                v-if="getImage(value)"
                class="img-fluid"
                :src="getImage(value)"
              />
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card-body>
  </b-modal>
</template>

<script lang="ts">
import BaseDataMixin from "@/mixins/RollerDriveBeltBaseData";
import { RollerDriveParams } from "@/model/roller-drive/roller-drive-params";
import { getSketchPath } from "@/utils/common-utils";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [BaseDataMixin],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    getImage(id: number) {
      return getSketchPath(`rb_prf_${id}.png`);
    },
  },
  computed: {
    getParams(): RollerDriveParams {
      return this.$store.getters["RollerDriveBeltParams/getParams"];
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/custom-bootstrap";
.drive-image {
  padding: 5px;
}

.drive-image-selected {
  border: 3px solid rgba($info, 0.2);
}

.drive-image:hover {
  background-color: rgba($info, 0.2);
}
</style>
