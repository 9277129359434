import Vue, { createApp } from "vue";
import router from "./router";
import store from "./store";
import { i18n } from "@/plugins/i18n";

import BootstrapVue from 'bootstrap-vue';

import fontAwesomeInit from "@/plugins/font-awesome";

import "@/plugins/date-fns";
import "@/scss/custom-bootstrap.scss";
import "@/scss/style.scss";
import App from "./App.vue";

/**
 * Initialize Bootstrap
 *
 * "Vue.use" is necessary to avoid the error "Multiple instances of Vue detected!".
 */
Vue.use(BootstrapVue);

const app = createApp(App as any);

/**
 * Substitute Vue.config.productionTip = false;
 * In Vue 3, Vue.config.productionTip has been removed.
 */
app.config.warnHandler = () => {
  return null;
};

// i18n global properties $t
app.config.globalProperties.$t = i18n.global.t;

// Initialize Font Awesome
fontAwesomeInit(app);

app.use(router).use(store).use(i18n);

router.isReady().then(() => {
  app.mount("#app");
});
