import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ProjectApi } from "@/api/";
import { KeyValueObject } from "@/model/key-value-object";
import { Project } from "@/model/project/project";
import { ProjectShort } from "@/model/project/project-short";
import router from "@/router";

import {
  getIntitialProject,
  removeProjectFromRoute,
  selectBelt,
  selectInitialBelt,
  updateConfig,
} from "../helpers/project.helper";
import { Customer } from "@/model/project/customer";
import {
  endLoadingBar,
  errorNotify,
  startLoadingBar,
  successNotify,
} from "../helpers/global.helper";
import deepcopy from "deepcopy";

@Module({ namespaced: true })
class ProjectStore extends VuexModule {
  public projects: ProjectShort[] = [];
  public project: Project = getIntitialProject("fb");
  public loadingState = false;
  private oldProject: Project = getIntitialProject("fb");
  public selectedId = -1;

  get getProjects(): ProjectShort[] {
    const beltType = router.currentRoute.value.meta.beltType as string;
    return this.projects.filter((project) => project.beltType === beltType);
  }

  get getProject(): Project {
    return this.project;
  }

  get isLoading(): boolean {
    return this.loadingState;
  }

  @Mutation
  public setProjects(projects: ProjectShort[]): void {
    this.projects = projects;
  }

  @Mutation
  public setProject(project: Project): void {
    this.project = deepcopy(project);
    this.oldProject = deepcopy(this.project);
  }

  @Mutation
  public setCustomer(customer: Customer) {
    this.project.customer = customer;
    this.oldProject.customer = customer;
  }

  @Mutation
  public setBeltParams(beltParams: KeyValueObject) {
    if (this.project) {
      this.project.beltConfig = beltParams;
    }
  }

  @Mutation
  public removeProject(id: number) {
    this.projects = this.projects.filter((project) => project.id !== id);
  }

  @Mutation
  public setLoadingState(state: boolean): void {
    this.loadingState = state;
  }

  @Action
  public async fetchProjects(): Promise<void> {
    this.context.commit("setLoadingState", true);
    const beltType = router.currentRoute.value.meta.beltType as string;

    await ProjectApi.getProjects(beltType)
      .then((projects) => this.context.commit("setProjects", projects))
      .catch((err) => errorNotify(err.message))
      .finally(() => this.context.commit("setLoadingState", false));
  }

  @Action
  public async fetchProject(projectId: number): Promise<void> {
    const beltType = router.currentRoute.value.meta.beltType as string;
    return await ProjectApi.getProject(projectId, beltType)
      .then((project) => {
        this.context.commit("setProject", project);
      })
      .catch((err) => errorNotify(err.message));
  }

  @Action
  public async selectProject(projectId: number): Promise<void> {
    const beltType = router.currentRoute.value.meta.beltType as string;
    if (projectId < 0) {
      this.context.commit("setProject", getIntitialProject(beltType));
      selectInitialBelt(this.context.dispatch, beltType);
      return;
    }

    return await ProjectApi.getProject(projectId, beltType)
      .then((project) => {
        this.context.commit("setProject", project);
        selectBelt(this.context.dispatch, beltType, this.project.beltConfig);
      })
      .catch((err) => {
        errorNotify(err.message);
        this.context.dispatch("initialProject", undefined);
      });
  }

  @Action
  public async createOrUpdateProject(): Promise<void> {
    updateConfig(this.context, this.getProject);

    if (this.project.id) {
      await ProjectApi.updateProject(this.project.id, this.project)
        .then((project) => this.context.commit("setProject", project))
        .then(() => successNotify("Project successfully updated"));
    } else {
      await ProjectApi.createProject(this.project)
        .then((project) => {
          this.context.commit("setProject", project);
          return project;
        })
        .then((project) => {
          const id = project.id;

          if (id) {
            router.push({ query: { project: id.toString() } });
          }
        })
        .then(() => successNotify("Project successfully created"));
    }
  }

  @Action
  public updateCustomer(customer: Customer) {
    this.context.commit("setCustomer", customer);
  }

  @Action
  public async copy(projectId: number): Promise<Project> {
    startLoadingBar();
    return await ProjectApi.copyProject(projectId)
      .then((project) => {
        this.context.dispatch("selectProject", project.id);
        return project;
      })
      .finally(() => endLoadingBar());
  }

  @Action
  public resetProject(): void {
    this.context.commit("setProject", deepcopy(this.oldProject));
  }

  @Action
  public initialProject() {
    removeProjectFromRoute();
    this.context.dispatch("selectProject", -1);
  }

  @Action
  public destroyProject() {
    const beltType = router.currentRoute.value.meta.beltType as string;
    removeProjectFromRoute();
    this.context.commit("setProject", getIntitialProject(beltType));
  }

  @Action
  public async deleteProject(id: number): Promise<void> {
    startLoadingBar();
    await ProjectApi.deleteProject(id)
      .then(() => this.context.commit("removeProject", id))
      .then(() => {
        if (Number(router.currentRoute.value.query.project) === id) {
          this.context.dispatch("initialProject", undefined);
        }
      })
      .catch(() => errorNotify(`Project cannot be deleted`))
      .finally(() => endLoadingBar());
  }
}
export default ProjectStore;
