import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class SuitableBeltWarningStore extends VuexModule {
  private messages: string[] = [];

  get getMessages() {
    return this.messages;
  }

  @Mutation
  public setAlert(messages: string[]): void {
    this.messages = messages;
  }

  @Action
  public clearAlert(): void {
    this.context.commit("setAlert", []);
  }

  @Action
  public alert(messages: string[]): void {
    this.context.commit("setAlert", messages);
  }
}
export default SuitableBeltWarningStore;
