import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class SiteSpinner extends VuexModule {
  public loadState = false;

  get isLoading(): boolean {
    return this.loadState;
  }

  @Mutation
  public setLoadState(state: boolean): void {
    this.loadState = state;
  }

  @Action
  public startLoading(): void {
    this.context.commit("setLoadState", true);
  }

  @Action
  public stopLoad(): void {
    this.context.commit("setLoadState", false);
  }
}
export default SiteSpinner;
