import {
  catchError,
  urlPrefix,
  config,
  axios,
  downloadBeltDocument,
  getCalculationQuery,
} from "./base-api";

import { BaseData } from "@/model/roller-drive/base-data";
import { KeyValueObject } from "@/model/key-value-object";
import { RollerDriveBelt } from "@/model/roller-drive/roller-drive";
import { RollerDriveReport } from "@/model/roller-drive/roller-drive-report";

const urlBaseData = `${urlPrefix}/roller-drive-belt`;

export default {
  async baseData(): Promise<BaseData> {
    return await axios
      .get(`${urlBaseData}/base-data`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async initialCalculate(): Promise<RollerDriveBelt> {
    return await axios
      .get(`${urlBaseData}/calculate/initial`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async calculate(
    params: KeyValueObject | undefined,
    beltId: string | undefined,
    projectId: number | undefined,
  ): Promise<RollerDriveBelt> {
    return await axios
      .post(
        `${urlBaseData}/calculate${
          beltId || projectId
            ? "?" + getCalculationQuery(beltId, projectId)
            : ""
        }`,
        params,
        config,
      )
      .then((response) => response.data)
      .catch(catchError);
  },
  async report(reportData: RollerDriveReport) {
    return await axios
      .post(`${urlBaseData}/report`, reportData, {
        responseType: "arraybuffer",
      })
      .then((response) => downloadBeltDocument(response, "roller-drive-belt"))
      .catch(catchError);
  },
};
