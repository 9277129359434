import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { TangentialBeltSummary } from "@/model/tangential-belt/tangential-belt-summary";

@Module({ namespaced: true })
class TangentialBeltSummaryStore extends VuexModule {
  public summary: TangentialBeltSummary | undefined = undefined;

  get getSummary(): TangentialBeltSummary | undefined {
    return this.summary;
  }

  @Mutation
  public setSummary(summary: TangentialBeltSummary): void {
    this.summary = summary;
  }
}
export default TangentialBeltSummaryStore;
