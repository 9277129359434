export function decimal(value: any, digits: number | undefined): number {
  if (!value || typeof value !== "number") return value;

  const numberValue = Number(value);
  return Number(numberValue.toFixed(digits || 0));
}

export function allowedValuesFilter(
  values: any[],
  allowedValues: any[] | undefined | null,
  key = "id",
): any[] {
  if (!allowedValues) {
    return values;
  }

  return values.filter((value) => allowedValues.includes(value[key]));
}
