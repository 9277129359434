import { catchError, urlPrefix, config, axios } from "./base-api";

import { Project } from "@/model/project/project";
import { ProjectShort } from "@/model/project/project-short";

const urlBaseData = `${urlPrefix}/project`;

export default {
  async getProjects(beltType: string): Promise<ProjectShort[]> {
    return await axios
      .get(`${urlBaseData}?beltType=${beltType}`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async getProject(projectId: number, beltType: string): Promise<Project> {
    return await axios
      .get(`${urlBaseData}/${projectId}?beltType=${beltType}`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async createProject(project: Project): Promise<Project> {
    return await axios
      .post(`${urlBaseData}`, project, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async copyProject(projectId: number): Promise<Project> {
    return await axios
      .post(`${urlBaseData}/${projectId}`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async updateProject(projectId: number, project: Project): Promise<Project> {
    return await axios
      .put(`${urlBaseData}/${projectId}`, project, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async deleteProject(projectId: number): Promise<Project> {
    return await axios
      .delete(`${urlBaseData}/${projectId}`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
};
