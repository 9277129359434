import { warningNotify } from "./global.helper";
import { FlatBelt } from "@/model/flat-belt/flat-belt";
import { RollerDriveBelt } from "@/model/roller-drive/roller-drive";
import { TangentialBelt } from "@/model/tangential-belt/tangential-belt";
import router from "@/router";

// eslint-disable-next-line
const calls: { [key: string]: any } = {
  fb: {
    setParams: "FlatBeltParams/setParams",
    setEnteredParam: "FlatBeltParams/setEnteredParam",
    setSummary: "FlatBeltSummary/setSummary",
    setReport: "FlatBeltReport/setParams",
    setSuitableBelts: "SuitableBelt/setBelts",
    setUnuitableBelts: "UnsuitableBelt/setBelts",
  },
  tb: {
    setParams: "TangentialBeltParams/setParams",
    setEnteredParam: "TangentialBeltParams/setEnteredParam",
    setSummary: "TangentialBeltSummary/setSummary",
    setReport: "TangentialBeltReport/setParams",
    setSuitableBelts: "SuitableBelt/setBelts",
    setUnuitableBelts: "UnsuitableBelt/setBelts",
  },
  rb: {
    setParams: "RollerDriveBeltParams/setParams",
    setEnteredParam: "RollerDriveBeltParams/setEnteredParam",
    setSummary: "RollerDriveBeltSummary/setSummary",
    setReport: "RollerDriveBeltReport/setParams",
    setSuitableBelts: "SuitableBelt/setBelts",
    setUnuitableBelts: "UnsuitableBelt/setBelts",
  },
  notification: {
    error: "Notification/error",
  },
  alert: "InputWarning/alert",
};

// eslint-disable-next-line
export const calculation = (context: any, data: FlatBelt | TangentialBelt | RollerDriveBelt) => {
  const beltType = router.currentRoute.value.meta.beltType as string;
  if (data && data.params) {
    context.commit(calls[beltType].setParams, data.params, { root: true });

    for (const key of Object.keys(data.params)) {
      const value = data.params[key].value;
      const messages = data.params[key].message;

      if (messages) {
        if (messages.length > 0) {
          data.params[key].valid = false;
          messages.forEach((msg) => {
            warningNotify(msg);
          });
        }
      }

      if (
        value !== null &&
        value !== undefined &&
        !data.params[key].calculated
      ) {
        context.commit(
          calls[beltType].setEnteredParam,
          { key, value: data.params[key].value },
          { root: true },
        );
      }
    }

    if (data.summary) {
      context.commit(calls[beltType].setSummary, data.summary, {
        root: true,
      });
    }

    if (data.suitableBelts) {
      context.commit(calls[beltType].setSuitableBelts, data.suitableBelts, {
        root: true,
      });
    }

    if (data.report) {
      context.commit(calls[beltType].setReport, data.report, {
        root: true,
      });
    }

    if (data.unsuitableBelts) {
      context.commit(calls[beltType].setUnuitableBelts, data.unsuitableBelts, {
        root: true,
      });
    }

    context.dispatch(calls.alert, data.messages, {
      root: true,
    });
  } else {
    context.dispatch(calls.notification.error, "@fwm_010", {
      root: true,
    });
  }
};
