import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  endLoadingBar,
  errorNotify,
  startLoadingBar,
  successNotify,
} from "../helpers/global.helper";
import userApi from "@/api/server/user-api";
import { User } from "@/model/user/user";
import { loadLanguageAsync } from "@/plugins/i18n";

@Module({ namespaced: true })
class UserStore extends VuexModule {
  public user: User | undefined = undefined;
  public oldUser!: User;
  public loadingState = false;

  get getUser(): User | undefined {
    return this.user;
  }

  get isUserLoaded(): boolean {
    return !!this.user;
  }

  get isLoading(): boolean {
    return this.loadingState;
  }

  get getLanguage(): string | undefined {
    return this.user?.langCode;
  }

  @Mutation
  public setUser(user: User): void {
    this.user = { ...this.user, ...user };
    this.oldUser = Object.assign({}, this.user);
  }

  @Mutation
  public setLoadingState(state: boolean): void {
    this.loadingState = state;
  }

  @Action
  public async fetchUser(activateSiteSpinner = true): Promise<User> {
    startLoadingBar();
    this.context.commit("setLoadingState", true);
    if (activateSiteSpinner) {
      this.context.dispatch("SiteSpinner/startLoading", undefined, {
        root: true,
      });
    }

    return await userApi
      .get()
      .then((user) => {
        this.context.commit("setUser", user);
        return user;
      })
      .finally(() => {
        endLoadingBar();
        this.context.dispatch("SiteSpinner/stopLoad", undefined, {
          root: true,
        });
        this.context.commit("setLoadingState", false);
      });
  }

  @Action
  public async updateUser(): Promise<void> {
    if (this.user) {
      startLoadingBar();
      await userApi
        .update(this.user.id, this.user)
        .then(() => successNotify("Update user successful"))
        .catch((err) => errorNotify(err.message))
        .finally(() => endLoadingBar());
    }
  }

  @Action
  public resetUser(): void {
    loadLanguageAsync(this.oldUser.langCode);
    this.context.commit("setUser", this.oldUser);
  }
}
export default UserStore;
