import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { for: "username" }
const _hoisted_2 = { for: "email" }
const _hoisted_3 = { for: "new-password" }
const _hoisted_4 = { for: "verification-password" }
const _hoisted_5 = { class: "w-100 d-flex flex-row justify-content-between align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_invalid_feedback = _resolveComponent("b-form-invalid-feedback")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_input_group_append = _resolveComponent("b-input-group-append")!
  const _component_b_input_group = _resolveComponent("b-input-group")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_card_body = _resolveComponent("b-card-body")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    id: "user-create",
    size: "lg",
    "header-bg-variant": "primary",
    "header-text-variant": "white",
    "footer-bg-variant": "light",
    "footer-text-variant": "white",
    "body-bg-variant": "light",
    "body-class": "modal-body-no-spacing",
    "ok-only": "",
    "ok-variant": "secondary",
    scrollable: "",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.show) = $event)),
    title: _ctx.$t('Add user'),
    "ok-title": _ctx.$t('Close')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_card_body, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_form, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.$t("Username")), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_input, {
                        id: "username",
                        type: "text",
                        modelValue: _ctx.username,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.create()), ["enter"]))
                      }, null, 8, ["modelValue"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("E-Mail")), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_input, {
                        id: "email",
                        type: "email",
                        modelValue: _ctx.email,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                        state: _ctx.checkEmail(),
                        onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.create()), ["enter"])),
                        "aria-describedby": "email email-feedback"
                      }, null, 8, ["modelValue", "state"]),
                      _createVNode(_component_b_form_invalid_feedback, { id: "email-feedback" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" E-Mail is not valid ")
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("New password")), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input_group, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            id: "new-password",
                            type: _ctx.showPassword ? 'text' : 'password',
                            modelValue: _ctx.password,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password) = $event)),
                            onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.create()), ["enter"]))
                          }, null, 8, ["type", "modelValue"]),
                          _createVNode(_component_b_input_group_append, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_button, {
                                class: "password-display-btn",
                                tabindex: "-1",
                                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.showPassword)
                                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                        key: 0,
                                        icon: ['far', 'eye-slash']
                                      }))
                                    : _createCommentVNode("", true),
                                  (!_ctx.showPassword)
                                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                        key: 1,
                                        icon: ['far', 'eye']
                                      }))
                                    : _createCommentVNode("", true)
                                ], undefined, true),
                                _: 1
                              })
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("Password verification")), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input_group, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            id: "verification-password",
                            type: _ctx.showVerification ? 'text' : 'password',
                            modelValue: _ctx.verificationPassword,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.verificationPassword) = $event)),
                            onKeyup: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.create()), ["enter"]))
                          }, null, 8, ["type", "modelValue"]),
                          _createVNode(_component_b_input_group_append, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_button, {
                                class: "password-display-btn",
                                tabindex: "-1",
                                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showVerification = !_ctx.showVerification))
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.showVerification)
                                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                        key: 0,
                                        icon: ['far', 'eye-slash']
                                      }))
                                    : _createCommentVNode("", true),
                                  (!_ctx.showVerification)
                                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                        key: 1,
                                        icon: ['far', 'eye']
                                      }))
                                    : _createCommentVNode("", true)
                                ], undefined, true),
                                _: 1
                              })
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { "offset-md": "3" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_checkbox, {
                        id: "is-admin",
                        name: "is-admin",
                        modelValue: _ctx.isAdmin,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isAdmin) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("Is admin")), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["modelValue"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      })
    ]),
    "modal-footer": _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_b_button, {
          variant: "primary",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.create()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Save")), 1)
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_button, {
          variant: "secondary",
          class: "float-right",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.close()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Close")), 1)
          ], undefined, true),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "ok-title"]))
}