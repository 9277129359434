import { decimal } from "./filter-utils";
import { NameObject } from "@/model/name-object";
import { StatisticBeltCountYearMonth } from "@/model/statistic/statistic-belt-count-year-month";
import { monthYear, monthYear1 } from "@/plugins/date-fns";
import { StatisticBeltCount } from "@/model/statistic/statistic-belt-count";

export const getSketchPath = (filename: string) => {
  if (filename) {
    return require(`@/assets/images/${filename}`);
  }
};

export const colorMap: { [key: string]: string } = {
  fb: "#003CA0",
  tb: "#EE6300",
  rb: "#960000",
  all: "#00BEFF",
};

export const colorMapAsArray = [colorMap.fb, colorMap.tb, colorMap.rb];

export const sortByName = (a: NameObject, b: NameObject) =>
  a.name > b.name ? 1 : b.name > a.name ? -1 : 0;

export const reduceYearCreated = (
  acc: StatisticBeltCountYearMonth[],
  obj: StatisticBeltCountYearMonth,
) => {
  const res = acc.find(
    (item) =>
      monthYear(item.date.toString()) === monthYear(obj.date.toString()),
  );
  if (res) {
    res.key = "belt";
    res.name = "Belt";
    res.count = +res.count + +obj.count;
    res.percent = decimal(res.percent + +obj.percent, 2);
    return acc;
  }

  acc.push(obj);
  return acc;
};

export const reduceYearCreateBelts = (
  acc: { x: Date; y: number }[],
  obj: { x: Date; y: number },
) => {
  let res = undefined;
  if (acc.length > 0) {
    res = acc.find((item) => monthYear1(item.x) === monthYear1(obj.x));
  }
  if (res) {
    res.y = +res.y + +obj.y;
    return acc;
  }

  acc.push(obj);
  return acc;
};

export const swapArrayItems = function (
  arr: StatisticBeltCount[],
  indexA: number,
  indexB: number,
) {
  const temp = arr[indexA];
  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
  return arr;
};

export const checkEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  if (!email) {
    return;
  }

  return emailRegex.test(email);
};
