import { defineComponent, getCurrentInstance } from "vue";
import { BModal, BvToast } from "bootstrap-vue";

const BootstrapHelpers = defineComponent({
  data() {
    return {
      bvModal: null as BModal | null,
      bvToast: null as BvToast | null,
    };
  },
  mounted() {
    const instance = getCurrentInstance() as any;
    this.bvModal = instance.ctx._bv__modal;
    this.bvToast = instance.ctx._bv__toast;
  },
});

export default BootstrapHelpers;
