import deepcopy from "deepcopy";

import { TangentialBeltParams } from "@/model/tangential-belt/tangential-belt-params";

export const initialParams = {
  som: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  sod: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  pushing: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  intenabled: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  dm: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  nm: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  du: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  d1: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  n1: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  dwh: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  nwh: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  v: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  z: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  beta: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  nos: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  ds: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  pm: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  pmhp: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  c: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  driving: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  usrelonge: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  usrelong: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  l: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  s: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  epstur: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  dedmm: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  dedp: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  b0min: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  b0: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
};

export function getInitialParams(): TangentialBeltParams {
  return deepcopy(initialParams);
}
