export class StorageUtils {
  public static setData(key: string, data: object) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  public static getData(key: string) {
    const data = sessionStorage.getItem(key);

    if (data) {
      return JSON.parse(data);
    }
  }

  public static dataExists(key: string) {
    return !!sessionStorage.getItem(key);
  }

  public static removeData(key: string) {
    sessionStorage.removeItem(key);
  }

  public static clearAll() {
    sessionStorage.clear();
  }
}
