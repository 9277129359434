import axios, { AxiosResponse } from "axios";
import { catchError, urlPrefix, config } from "./base-api";
import { Credential } from "@/model/credential";

export default {
  async login(credentials: Credential): Promise<void> {
    return await axios
      .post(`${urlPrefix}/login`, credentials, config)
      .then((_) => Promise.resolve())
      .catch((error) => Promise.reject());
  },
};
