import { catchError, urlPrefix, config, axios } from "./base-api";

import { Translation } from "@/model/translation";

const urlBaseData = `${urlPrefix}/data`;

export default {
  async language(): Promise<Translation> {
    return await axios
      .get(`${urlBaseData}/translation/language`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async translation(language: string): Promise<Translation> {
    return await axios
      .get(`${urlBaseData}/translation/${language}`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async downloadQuestionnaire(name: string, file: string) {
    return await axios
      .get(`${urlBaseData}/download/document/${file}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  },
};
