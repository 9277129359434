import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { SuitableBelt } from "@/model/suitable-belt";

@Module({ namespaced: true })
class SuitableBeltStore extends VuexModule {
  public belts: SuitableBelt[] = [];

  get getBelts(): SuitableBelt[] {
    return this.belts;
  }

  @Mutation
  public setBelts(belts: SuitableBelt[]): void {
    this.belts = belts;
  }

  @Action
  public initialSuitableBelts() {
    this.context.commit("setBelts", []);
  }
}
export default SuitableBeltStore;
