import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41aee135"),n=n(),_popScopeId(),n)
const _hoisted_1 = { for: "language" }
const _hoisted_2 = { for: "name" }
const _hoisted_3 = { for: "user-email" }
const _hoisted_4 = { for: "address" }
const _hoisted_5 = { for: "zipcode" }
const _hoisted_6 = { for: "country" }
const _hoisted_7 = { for: "note" }
const _hoisted_8 = { class: "w-100 d-flex flex-row justify-content-between align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_invalid_feedback = _resolveComponent("b-form-invalid-feedback")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    id: "user-settings",
    size: "lg",
    "header-bg-variant": "primary",
    "header-text-variant": "white",
    "footer-bg-variant": "light",
    "footer-text-variant": "white",
    "body-bg-variant": "light",
    "ok-only": "",
    "ok-variant": "secondary",
    scrollable: "",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.show) = $event)),
    "ok-title": _ctx.$t('Close'),
    title: _ctx.$t('User Settings')
  }, {
    default: _withCtx(() => [
      (!_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_b_form, {
            key: 0,
            ref: "form"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.$t(`Language`)), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_select, {
                        id: "language",
                        "value-field": "code",
                        "text-field": "name",
                        modelValue: _ctx.getUser.langCode,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getUser.langCode) = $event)),
                        options: _ctx.language
                      }, null, 8, ["modelValue", "options"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t(`Name`)), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_input, {
                        id: "name",
                        type: "text",
                        modelValue: _ctx.getUser.name,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getUser.name) = $event))
                      }, null, 8, ["modelValue"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t(`E-Mail`)), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_input, {
                        id: "user-email",
                        type: "email",
                        modelValue: _ctx.getUser.email,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getUser.email) = $event)),
                        state: _ctx.onCheckEmail(_ctx.getUser.email)
                      }, null, 8, ["modelValue", "state"]),
                      _createVNode(_component_b_form_invalid_feedback, { id: "user-email-feedback" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("E-Mail is not valid")), 1)
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t(`Address`)), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_input, {
                        id: "address",
                        type: "text",
                        modelValue: _ctx.getUser.address,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getUser.address) = $event))
                      }, null, 8, ["modelValue"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t(`Zip / City`)), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_input, {
                        id: "zipcode",
                        type: "text",
                        modelValue: _ctx.getUser.zipCity,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.getUser.zipCity) = $event))
                      }, null, 8, ["modelValue"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t(`Country`)), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_input, {
                        id: "country",
                        type: "text",
                        modelValue: _ctx.getUser.country,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.getUser.country) = $event))
                      }, null, 8, ["modelValue"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { sm: "3" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t(`Note`)), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { sm: "9" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_textarea, {
                        id: "note",
                        modelValue: _ctx.getUser.note,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.getUser.note) = $event)),
                        rows: "3"
                      }, null, 8, ["modelValue"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "align-items-center my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { "offset-md": "3" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_checkbox, {
                        id: "auto-save",
                        name: "auto-save",
                        modelValue: _ctx.getUser.autosave,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.getUser.autosave) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(`Save changes automatically`)), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["modelValue"])
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ]),
    "modal-footer": _withCtx(() => [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_b_button, {
          variant: "primary",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onSave()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Save")), 1)
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_button, {
          variant: "secondary",
          class: "float-right",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onReset()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Close")), 1)
          ], undefined, true),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "ok-title", "title"]))
}