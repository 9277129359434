import { Dispatch } from "vuex";
import deepcopy from "deepcopy";

import router from "@/router";

import { warningNotify } from "./global.helper";
import { Customer } from "@/model/project/customer";
import { KeyValueObject } from "@/model/key-value-object";
import { Project } from "@/model/project/project";

const beltConfigMapper: { [key: string]: string } = {
  fb: "FlatBeltParams/getEnteredParams",
  tb: "TangentialBeltParams/getEnteredParams",
  rb: "RollerDriveBeltParams/getEnteredParams",
};

const loadBeltMapper: { [key: string]: string } = {
  fb: "FlatBeltParams/loadProjectConfig",
  tb: "TangentialBeltParams/loadProjectConfig",
  rb: "RollerDriveBeltParams/loadProjectConfig",
};

const initialBeltMapper: { [key: string]: string } = {
  fb: "FlatBeltParams/initialParams",
  tb: "TangentialBeltParams/initialParams",
  rb: "RollerDriveBeltParams/initialParams",
};

const initialCustomer = {
  id: undefined,
  name: "",
  email: "",
  address: "",
  zipCity: "",
  city: "",
  country: "",
  note: "",
};

export function getIntitialProject(beltType: string): Project {
  const data = deepcopy({
    id: undefined,
    beltType: "fb",
    name: "",
    dateCreated: undefined,
    lastUpdated: undefined,
    customer: null,
    beltConfig: undefined,
    autoSave: false,
  });
  data.beltType = beltType;
  return data;
}

export function getIntitialCustomer(): Customer {
  return deepcopy(initialCustomer);
}

// eslint-disable-next-line
export function updateConfig(context: any, project: Project): void {
  if (project.beltType in loadBeltMapper) {
    const config = context.rootGetters[beltConfigMapper[project.beltType]];
    context.commit("setBeltParams", config);
    return;
  }

  warningNotify("Unknown type for config update");
}

export function selectBelt(
  dispatch: Dispatch,
  beltType: string,
  beltConfig: KeyValueObject | undefined,
): void {
  if (beltType in loadBeltMapper) {
    dispatch(loadBeltMapper[beltType], beltConfig, {
      root: true,
    });
    return;
  }

  warningNotify("Unknown type for belt selection");
}

export function selectInitialBelt(dispatch: Dispatch, beltType: string): void {
  if (beltType in initialBeltMapper) {
    const options = {
      root: true,
    };

    dispatch(initialBeltMapper[beltType], undefined, options);
    return;
  }

  warningNotify("Unknown type for initial belt");
}

export function removeProjectFromRoute() {
  if (router.currentRoute.value.query.project) {
    router.push(
      router.currentRoute.value.fullPath.replace(
        `project=${router.currentRoute.value.query.project}`,
        "",
      ),
    );
  }
}
