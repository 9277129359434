import { createStore } from "vuex";

import SiteSpinner from "@/store/modules/site-spinner.module";
import HeaderLoadingBar from "@/store/modules/header-loading-bar.module";
import Notification from "@/store/modules/notification.module";
import User from "@/store/modules/user.module";
import Users from "@/store/modules/users.module";
import BaseData from "@/store/modules/base-data.module";
import Project from "@/store/modules/project.module";

import FlatBeltParams from "@/store/modules/flat-belt/flat-belt-params.module";
import FlatBeltBaseData from "@/store/modules/flat-belt/flat-belt-base-data.module";
import FlatBeltLoads from "@/store/modules/flat-belt/flat-belt-load.module";
import FlatBeltSummary from "@/store/modules/flat-belt/flat-belt-summary.module";
import FlatBeltReport from "@/store/modules/flat-belt/flat-belt-report.module";

import RollerDriveBeltParams from "@/store/modules/roller-drive-belt/roller-drive-belt-params.module";
import RollerDriveBeltBaseData from "@/store/modules/roller-drive-belt/roller-drive-belt-base-data.module";
import RollerDriveBeltSummary from "@/store/modules/roller-drive-belt/roller-drive-belt-summary.module";
import RollerDriveBeltReport from "@/store/modules/roller-drive-belt/roller-drive-belt-report.module";

import TangentialBeltParams from "@/store/modules/tangential-belt/tangential-belt-params.module";
import TangentialBeltBaseData from "@/store/modules/tangential-belt/tangential-belt-base-data.module";
import TangentialBeltSummary from "@/store/modules/tangential-belt/tangential-belt-summary.module";
import TangentialBeltReport from "@/store/modules/tangential-belt/tangential-belt-report.module";

import InputWarning from "@/store/modules/input-warning.module";
import SuitableBelt from "@/store/modules/suitable-belt.module";
import SuitableBeltWarning from "@/store/modules/suitable-belt-warning.module";
import UnsuitableBelt from "@/store/modules/unsuitable-belt.module";

const store = createStore({
  modules: {
    SiteSpinner,
    HeaderLoadingBar,
    Notification,
    User,
    Users,
    BaseData,
    Project,
    FlatBeltParams,
    FlatBeltBaseData,
    FlatBeltLoads,
    FlatBeltSummary,
    FlatBeltReport,
    RollerDriveBeltParams,
    RollerDriveBeltBaseData,
    RollerDriveBeltSummary,
    RollerDriveBeltReport,
    TangentialBeltParams,
    TangentialBeltBaseData,
    TangentialBeltSummary,
    TangentialBeltReport,
    InputWarning,
    SuitableBelt,
    SuitableBeltWarning,
    UnsuitableBelt,
  },
});
export default store;

export type RootState = ReturnType<typeof store.getters>;
