import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import router from "@/router";
import { FlatBeltApi } from "@/api/index";
import { FlatBeltLoad } from "@/model/flat-belt/flat-belt-load";
import { errorNotify } from "@/store/helpers/global.helper";
import { StorageUtils } from "@/utils/storage-utils";

@Module({ namespaced: true })
class FlatBeltLoadsStore extends VuexModule {
  public loads: FlatBeltLoad[] = [];
  public loadingState = false;

  get getLoads(): FlatBeltLoad[] {
    return this.loads;
  }

  get isLoading(): boolean {
    return this.loadingState;
  }

  @Mutation
  public setLoads(loads: FlatBeltLoad[]): void {
    this.loads = loads;
  }

  @Mutation
  public setLoadingState(state: boolean): void {
    this.loadingState = state;
  }

  @Action
  public async fetchLoads(): Promise<void> {
    this.context.commit("setLoadingState", true);

    if (StorageUtils.dataExists("load-factor")) {
      const data = StorageUtils.getData("load-factor");
      this.context.commit("setLoads", data);
    } else {
      if (this.loads.length <= 0) {
        await FlatBeltApi.loads()
          .then((data) => {
            this.context.commit("setLoads", data);
            StorageUtils.setData("load-factor", data);
          })
          .catch((err) => errorNotify(err))
          .finally(() => {
            this.context.commit("setLoadingState", false);
          });
      }
    }
  }

  @Action
  public setLoad(value: number): void {
    if (router.currentRoute.value.name === "PowerTransmission") {
      this.context.commit("FlatBeltParams/setLoad", value, {
        root: true,
      });
      this.context.dispatch("FlatBeltParams/flatBeltCalculate", null, {
        root: true,
      });
    } else if (router.currentRoute.value.name === "TangentialBelts") {
      this.context.commit("TangentialBeltParams/setLoad", value, {
        root: true,
      });
      this.context.dispatch("TangentialBeltParams/tangentialCalculate", null, {
        root: true,
      });
    }
  }
}
export default FlatBeltLoadsStore;
