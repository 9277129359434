import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { getInitialParams } from "./flat-belt.helper";
import { FlatBeltApi } from "@/api/index";
import { FlatBeltParams } from "@/model/flat-belt/flat-belt-params";
import { KeyBeltParam } from "@/model/key-belt-param";
import { KeyValue } from "@/model/key-value";
import { KeyValueObject } from "@/model/key-value-object";
import {
  endLoadingBar,
  errorNotify,
  startLoadingBar,
} from "@/store/helpers/global.helper";
import { calculation } from "@/store/helpers/belt.helper";
import router from "@/router";

@Module({ namespaced: true })
class FlatBeltParamsStore extends VuexModule {
  public params: FlatBeltParams = getInitialParams();
  public enteredParams: KeyValueObject = {};

  get getParams(): FlatBeltParams {
    return this.params;
  }

  get getEnteredParams(): KeyValueObject {
    return this.enteredParams;
  }

  @Mutation
  public setParams(params: FlatBeltParams): void {
    this.params = { ...getInitialParams(), ...params };
  }

  @Mutation
  public setParam({ key, param }: KeyBeltParam): void {
    this.params[key] = param;
  }

  @Mutation
  public setEnteredParams(params: KeyValueObject): void {
    this.enteredParams = params;
  }

  @Mutation
  public setEnteredParam({ key, value }: KeyValue) {
    this.enteredParams[key] = value;
  }

  @Mutation
  public removeEnteredParam(key: string) {
    delete this.enteredParams[key];
  }

  @Mutation
  public setValueOfItem({ key, value }: KeyValue) {
    if (this.params) {
      this.params[key].value = value;
    }

    this.enteredParams[key] = value;
  }

  @Mutation
  public setLoad(value: number): void {
    this.params.c.value = value;

    if (this.enteredParams) {
      this.enteredParams.c = value;
    }
  }

  @Action
  public updateValueOfItem(data: KeyValue) {
    this.context.commit("setValueOfItem", data);
  }

  @Action
  public removeParam(key: string) {
    this.context.commit("removeEnteredParam", key);
    this.context.commit("setParam", {
      key,
      param: {
        value: null,
        allowedValues: null,
        calculated: false,
        valid: true,
        disabled: false,
      },
    });
  }

  @Action
  public loadProjectConfig(config: KeyValueObject): void {
    this.context.commit("setEnteredParams", config);
    this.context.dispatch("flatBeltCalculate");
  }

  @Action
  public async initialCalculate() {
    startLoadingBar();
    this.context.commit("setEnteredParams", {});
    return FlatBeltApi.initialCalculate()
      .then((data) => calculation(this.context, data))
      .catch((err) => errorNotify(err))
      .finally(() => endLoadingBar());
  }

  @Action
  public async flatBeltCalculate(id?: string): Promise<void> {
    const projectId = Number(router.currentRoute.value.query.project);

    if (this.params) {
      startLoadingBar();
      await FlatBeltApi.calculate(this.enteredParams, id, projectId)
        .then((data) => calculation(this.context, data))
        .catch((err) => errorNotify(err))
        .finally(() => endLoadingBar());
    }
  }

  @Action
  public initialParams() {
    this.context.commit("setEnteredParams", {});
    this.context.dispatch("initialCalculate", undefined);
    this.context.dispatch("InputWarning/alert", [], {
      root: true,
    });
  }

  @Action
  public destroy() {
    this.context.commit("setEnteredParams", {});
    this.context.commit("setParams", getInitialParams());
  }
}
export default FlatBeltParamsStore;
