<template>
  <b-modal
    id="factors-table"
    size="lg"
    header-bg-variant="primary"
    header-text-variant="white"
    footer-bg-variant="light"
    footer-text-variant="white"
    body-bg-variant="light"
    body-class="modal-body-no-spacing"
    ok-only
    ok-variant="secondary"
    scrollable
    :title="$t('Load Factors')"
    :ok-title="$t('Close')"
  >
    <b-card-body>
      <b-row class="justify-content-center align-items-center">
        <b-col class="my-1">
          <b-form-group
            :label="$t('Search')"
            label-cols-sm="2"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">{{
                  $t("Clear")
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      class="mb-0"
      striped
      bordered
      sort-by="description"
      :filter="filter"
      :fields="fields"
      :items="getLoads"
    >
      <template v-slot:thead-top>
        <b-tr>
          <b-th
            ><span class="sr-only">{{ $t("Sort of drive") }}</span></b-th
          >
          <b-th
            ><span class="sr-only">{{ $t("sortOfGeneralDrive") }}</span></b-th
          >
          <b-th class="text-center" colspan="3" variant="secondary">{{
            $t("3-phase AC motor")
          }}</b-th>
        </b-tr>
      </template>
      <template v-slot:cell(description)="row">
        <span v-html="row.value"></span>
      </template>
      <template v-slot:cell(general)="row">
        <b-link v-if="row.value" @click="selectValue(row.value)">{{
          row.value
        }}</b-link>
        <span v-else>-</span>
      </template>
      <template v-slot:cell(smooth)="row">
        <b-link v-if="row.value" @click="selectValue(row.value)">{{
          row.value
        }}</b-link>
        <span v-else>-</span>
      </template>
      <template v-slot:cell(stuM)="row">
        <b-link v-if="row.value" @click="selectValue(row.value)">{{
          row.value
        }}</b-link>
        <span v-else>-</span>
      </template>
    </b-table>
  </b-modal>
</template>

<script lang="ts">
import { FlatBeltLoad } from "@/model/flat-belt/flat-belt-load";
import BootstrapHelpers from "@/mixins/BootstrapHelpers";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [BootstrapHelpers],
  data() {
    return {
      filter: "",
      selected: [],
      fields: [
        {
          key: "description",
          label: this.$t("Application, sort of drive"),
        },
        {
          key: "general",
          label: this.$t("General"),
          thClass: "general-cell",
          tdClass: "general-cell",
        },
        {
          key: "smooth",
          label: this.$t("Smooth"),
          variant: "secondary",
          thClass: "smooth-ac-motor-cell",
          tdClass: "smooth-ac-motor-cell",
        },
        {
          key: "stuM",
          label: this.$t("Start-up Medium-smooth"),
          variant: "secondary",
          thClass: "medium-ac-motor-cell",
          tdClass: "medium-ac-motor-cell",
        },
      ],
    };
  },
  created(): void {
    this.fetchLoads();
  },
  methods: {
    selectValue(value: number) {
      this.setLoad(value);
      if (this.bvModal) {
        this.bvModal.hide("factors-table");
      }
    },
    fetchLoads(): Promise<void> {
      return this.$store.dispatch("FlatBeltLoads/fetchLoads");
    },
    setLoad(value: number): Promise<void> {
      return this.$store.dispatch("FlatBeltLoads/setLoad", value);
    },
  },
  computed: {
    getLoads(): FlatBeltLoad[] {
      return this.$store.getters["FlatBeltLoads/getLoads"];
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/custom-bootstrap";
.link-cell {
  color: $primary;
  cursor: pointer;
}

.general-cell {
  text-align: center;
  padding: 0.75rem 0 !important;
  width: 65px;
}

.smooth-ac-motor-cell {
  text-align: center;
  padding: 0.75rem 0 !important;
  width: 60px;
}

.medium-ac-motor-cell {
  text-align: center;
  padding: 0.75rem 0 !important;
  width: 110px;
}

.jerkin-ac-motor-cell {
  text-align: center;
  padding: 0.75rem 0 !important;
  width: 60px;
}
</style>
