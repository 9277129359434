import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { TangentialBeltApi } from "@/api/index";
import { TangentialBeltReport } from "@/model/tangential-belt/tangential-belt-report";
import {
  errorNotify,
  warningNotify,
  startLoadingBar,
  endLoadingBar,
} from "@/store/helpers/global.helper";

@Module({ namespaced: true })
class TangentialBeltReportStore extends VuexModule {
  public report: TangentialBeltReport | undefined = undefined;

  get getParams(): TangentialBeltReport | undefined {
    return this.report;
  }

  @Mutation
  public setParams(report: TangentialBeltReport): void {
    this.report = report;
  }

  @Action
  public download() {
    if (this.report) {
      startLoadingBar();
      TangentialBeltApi.report(this.report)
        .catch((err) => errorNotify(err))
        .finally(() => endLoadingBar());
    } else {
      warningNotify("No data available");
    }
  }
}
export default TangentialBeltReportStore;
