import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { FlatBeltSummary } from "@/model/flat-belt/flat-belt-summary";

@Module({ namespaced: true })
class FlatBeltSummaryStore extends VuexModule {
  public summary: FlatBeltSummary | undefined = undefined;

  get getSummary(): FlatBeltSummary | undefined {
    return this.summary;
  }

  @Mutation
  public setSummary(summary: FlatBeltSummary): void {
    this.summary = summary;
  }
}
export default FlatBeltSummaryStore;
