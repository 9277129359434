<template>
  <div
    id="app"
    :class="{ 'login-app': $route.meta.isSignIn, 'is-loading': isBarLoading }"
  >
    <Header class="sticky" v-if="!$route.meta.isSignIn" />
    <b-container
      class="header-margin py-4"
      fluid="lg"
      :class="{ 'login-container': $route.meta.isSignIn }"
    >
      <site-spinner class="spinner" v-if="isLoading" />
      <main v-if="!isLoading" :class="{ 'login-main': $route.meta.isSignIn }">
        <router-view />
      </main>
    </b-container>
    <Footer />
    <notification />
    <template v-if="getUser">
      <user-management-modal v-if="getUser.isAdmin" />
      <create-user-modal v-if="getUser.isAdmin" />
      <user-settings-modal />
      <user-change-password-modal />
      <projects-modal />
      <documentation-modal />
      <factors-table-modal />
      <sort-of-tangential-drive-modal />
      <pressure-roll-factor-modal />
    </template>
  </div>
</template>

<script lang="ts">
import { loadLanguageAsync } from "@/plugins/i18n";

import Footer from "@/components/layout/Footer.vue";
import Header from "@/components/layout/Header.vue";
import Notification from "@/components/layout/Notification.vue";
import SiteSpinner from "@/components/layout/SiteSpinner.vue";

import CreateUserModal from "@/components/modal/CreateUserModal.vue";
import DocumentationModal from "@/components/modal/DocumentationModal.vue";
import FactorsTableModal from "@/components/modal/FactorsTableModal.vue";
import ProjectsModal from "@/components/modal/ProjectsModal.vue";
import PressureRollFactorModal from "@/components/modal/PressureRollFactorModal.vue";
import SortOfTangentialDriveModal from "@/components/modal/SortOfTangentialDriveModal.vue";
import UserChangePasswordModal from "@/components/modal/UserChangePasswordModal.vue";
import UserManagementModal from "@/components/modal/UserManagementModal.vue";
import UserSettingsModal from "@/components/modal/UserSettingsModal.vue";

import { User } from "@/model/user/user";
import { defineComponent } from "vue";

// const headerLoadingBarStore = namespace("HeaderLoadingBar");
// const userStore = namespace("User");
// const siteSpinnerStore = namespace("SiteSpinner");
export default defineComponent({
  components: {
    CreateUserModal,
    DocumentationModal,
    FactorsTableModal,
    Footer,
    Header,
    Notification,
    ProjectsModal,
    PressureRollFactorModal,
    SiteSpinner,
    SortOfTangentialDriveModal,
    UserChangePasswordModal,
    UserManagementModal,
    UserSettingsModal,
  },
  created(): void {
    if (!this.getUser && this.$route.path !== "/sign-in") {
      this.fetchUser().then((user) => {
        loadLanguageAsync(user?.langCode);
      });
    }
  },
  methods: {
    fetchUser(): Promise<User> {
      return this.$store.dispatch("User/fetchUser");
    },
  },
  computed: {
    isBarLoading(): string {
      return this.$store.getters["HeaderLoadingBar/isBarLoading"];
    },
    getUser(): User {
      return this.$store.getters["User/getUser"];
    },
    isLoading(): boolean {
      return this.$store.getters["SiteSpinner/isLoading"];
    },
  },
});
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}

main {
  width: 100%;
  height: 100%;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

.login-app {
  height: 100vh;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.login-main {
  flex: 1 1 auto;
  min-height: 0px;
  display: flex;
  flex-wrap: wrap;
}

.login-container {
  flex: 1 1 auto;
  min-height: 0px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  width: 100%;
  min-width: 100%;
}
</style>
