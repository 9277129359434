import deepcopy from "deepcopy";

import { RollerDriveParams } from "@/model/roller-drive/roller-drive-params";

const initialParams: RollerDriveParams = {
  l0: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  m_: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  mt: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  b0: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  d2: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  sod: {
    value: 2,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  sg: {
    value: 2,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  dt: {
    value: 50,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  b: {
    value: 500,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  crm: {
    value: 1,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  as: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  d1: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  dps: {
    value: 1,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  beta: {
    value: 220,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  dmin: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  usrelonge: {
    value: false,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  usrelong: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  usrthicknesse: {
    value: false,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  usrthickness: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  divertere: {
    value: false,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  m1: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  nrd: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  accumulatione: {
    value: false,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  ap: {
    value: 1,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  mrs: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  inclinee: {
    value: false,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  it: {
    value: 0,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  ht: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  ip: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  acceleratione: {
    value: false,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  ma: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  vf: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  ta: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  motorpowere: {
    value: false,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  vf2: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  eta: {
    value: 80,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  pressurerollse: {
    value: false,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  i: {
    value: 1,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  m12: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  m1L: {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: null,
  },
  // eslint-disable-next-line
  'm_tr': {
    value: null,
    allowedValues: null,
    calculated: false,
    valid: true,
    disabled: false,
    message: undefined,
  },
};

export function getInitialParams(): RollerDriveParams {
  return deepcopy(initialParams);
}
