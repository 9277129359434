import { format, parseISO } from "date-fns";

export function dateTimeFile(date: string): string | undefined {
  if (!date) {
    return;
  }
  const isoDate = parseISO(date);
  return format(isoDate, "dd.MM.yyyy-HH.mm.ss");
}

export function dateTime(date: string | undefined): string | undefined {
  if (!date) {
    return;
  }
  const isoDate = parseISO(date);
  return format(isoDate, "dd.MM.yyyy HH:mm");
}

export function monthYear(date: string): string | undefined {
  if (!date) {
    return;
  }
  const isoDate = parseISO(date);
  return format(isoDate, "MMM yyyy");
}

export function monthYear1(date: Date): string {
  return format(date, "MMM yyyy");
}
