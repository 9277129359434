import axios from "axios"

const urlBaseData = `/api/ping`

export default {
  async ping(): Promise<boolean> {
    return await axios
    .get(urlBaseData)
    .then((_) => true)
    .catch((err) => {
      return false
    })
  },
}
