import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { TangentialBeltApi } from "@/api";
import { BaseData } from "@/model/tangential-belt/base-data";
import { BeltField } from "@/model/belt-field";
import { MotorPower } from "@/model/tangential-belt/motor-power";
import { TangentialDrive } from "@/model/tangential-belt/tangential-drive";
import { TypeDrive } from "@/model/type-drive";
import { SortMachine } from "@/model/tangential-belt/sort-of-machine";
import { StorageUtils } from "@/utils/storage-utils";
import { endLoadingBar, startLoadingBar } from "@/store/helpers/global.helper";

@Module({ namespaced: true })
class TangentialBeltBaseDataStore extends VuexModule {
  public fields: BeltField[] = [];
  public tangentialDrive: TangentialDrive[] = [];
  public sortMachines: SortMachine[] = [];
  public typeDrive: TypeDrive[] = [];
  public motorPower: MotorPower[] = [];

  get isLoaded(): boolean {
    return (
      this.fields.length > 0 &&
      this.tangentialDrive.length > 0 &&
      this.sortMachines.length > 0 &&
      this.typeDrive.length > 0 &&
      this.motorPower.length > 0
    );
  }

  get getFields(): BeltField[] {
    return this.fields;
  }

  get getTangentialDrive(): TangentialDrive[] {
    return this.tangentialDrive;
  }

  get getSortMachines(): SortMachine[] {
    return this.sortMachines;
  }

  get getTypeDrive(): TypeDrive[] {
    return this.typeDrive;
  }

  get getMotorPower(): MotorPower[] {
    return this.motorPower;
  }

  @Mutation
  public setFields(fields: BeltField[]): void {
    this.fields = fields;
  }

  @Mutation
  public setTangentialDrive(tangentialDrive: TangentialDrive[]): void {
    this.tangentialDrive = tangentialDrive;
  }

  @Mutation
  public setSortMachines(sortMachines: SortMachine[]): void {
    this.sortMachines = sortMachines;
  }

  @Mutation
  public setTypeDrive(typeDrive: TypeDrive[]): void {
    this.typeDrive = typeDrive;
  }

  @Mutation
  public setMotorPower(motorPower: MotorPower[]): void {
    this.motorPower = motorPower;
  }

  @Action({ rawError: true })
  public async baseData(): Promise<void> {
    if (StorageUtils.dataExists("rb-data")) {
      const data = StorageUtils.getData("rb-data");
      this.context.commit("setFields", data.fields);
      this.context.commit("setTangentialDrive", data.tangentialDrive);
      this.context.commit("setSortMachines", data.sortMachines);
      this.context.commit("setTypeDrive", data.typeDrive);
      this.context.commit("setMotorPower", data.motorPower);
    } else {
      startLoadingBar();
      await TangentialBeltApi.baseData()
        .then((data: BaseData) => {
          this.context.commit("setFields", data.fields);
          this.context.commit("setTangentialDrive", data.tangentialDrive);
          this.context.commit("setSortMachines", data.sortMachines);
          this.context.commit("setTypeDrive", data.typeDrive);
          this.context.commit("setMotorPower", data.motorPower);
          StorageUtils.setData("rb-data", data);
        })
        .finally(() => endLoadingBar());
    }
  }
}
export default TangentialBeltBaseDataStore;
