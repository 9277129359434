import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { FlatBeltApi } from "@/api";
import { BaseData } from "@/model/flat-belt/base-data";
import { BeltField } from "@/model/belt-field";
import { DrivePulley } from "@/model/flat-belt/drive-pulleys";
import { RunningCondition } from "@/model/flat-belt/running-condition";
import { endLoadingBar, startLoadingBar } from "@/store/helpers/global.helper";
import { StorageUtils } from "@/utils/storage-utils";

@Module({ namespaced: true })
class FlatBeltBaseDataStore extends VuexModule {
  public fields: BeltField[] = [];
  public drivePulleys: DrivePulley[] = [];
  public runningConditions: RunningCondition[] = [];

  get isLoaded(): boolean {
    return (
      this.fields.length > 0 &&
      this.drivePulleys.length > 0 &&
      this.runningConditions.length > 0
    );
  }

  get getFields(): BeltField[] {
    return this.fields;
  }

  get getDrivePulleys(): DrivePulley[] {
    return this.drivePulleys;
  }

  get getRunningConditions(): RunningCondition[] {
    return this.runningConditions;
  }

  @Mutation
  public setFields(fields: BeltField[]): void {
    this.fields = fields;
  }

  @Mutation
  public setDrivePulleys(drivePulleys: DrivePulley[]): void {
    this.drivePulleys = drivePulleys;
  }

  @Mutation
  public setRunningConditions(runningConditions: RunningCondition[]): void {
    this.runningConditions = runningConditions;
  }

  @Action({ rawError: true })
  public async baseData(): Promise<void> {
    if (StorageUtils.dataExists("fb-data")) {
      const data = StorageUtils.getData("fb-data");
      this.context.commit("setFields", data.fields);
      this.context.commit("setDrivePulleys", data.drivePulleys);
      this.context.commit("setRunningConditions", data.runningConditions);
    } else {
      startLoadingBar();
      await FlatBeltApi.baseData()
        .then((data: BaseData) => {
          if(!data) return
          this.context.commit("setFields", data.fields);
          this.context.commit("setDrivePulleys", data.drivePulleys);
          this.context.commit("setRunningConditions", data.runningConditions);
          StorageUtils.setData("fb-data", data);
        })
        .finally(() => endLoadingBar());
    }
  }
}
export default FlatBeltBaseDataStore;
