import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { FlatBeltApi } from "@/api/index";
import { FlatBeltReport } from "@/model/flat-belt/flat-belt-report";
import {
  errorNotify,
  warningNotify,
  startLoadingBar,
  endLoadingBar,
} from "@/store/helpers/global.helper";

@Module({ namespaced: true })
class FlatBeltReportStore extends VuexModule {
  public report: FlatBeltReport | undefined = undefined;

  get getParams(): FlatBeltReport | undefined {
    return this.report;
  }

  @Mutation
  public setParams(report: FlatBeltReport): void {
    this.report = report;
  }

  @Action
  public download() {
    if (this.report) {
      startLoadingBar();
      FlatBeltApi.report(this.report)
        .catch((err) => errorNotify(err))
        .finally(() => endLoadingBar());
    } else {
      warningNotify("No data available");
    }
  }
}
export default FlatBeltReportStore;
