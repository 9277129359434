<template>
  <b-modal
    id="projects"
    size="lg"
    header-bg-variant="primary"
    header-text-variant="white"
    footer-bg-variant="light"
    footer-text-variant="white"
    body-bg-variant="light"
    body-class="modal-body-no-spacing"
    ok-only
    ok-variant="secondary"
    scrollable
    :ok-title="$t('Close')"
    :title="$t('Search Project')"
  >
    <b-card-body>
      <b-row>
        <b-col class="my-1">
          <b-form-group
            label-cols-sm="2"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
            :label="$t('Search Project')"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">{{
                  $t("Clear")
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <site-spinner class="spinner" v-if="isLoading" />
    <b-table
      class="mb-0"
      striped
      sticky-header
      responsive="sm"
      v-if="!isLoading"
      :fields="fields"
      :items="getProjects"
      :filter="filter"
    >
      <template v-slot:cell(dateCreated)="row">
        {{ row.item.dateCreated || dateTime }}
      </template>
      <template v-slot:cell(action)="row">
        <b-button-group>
          <b-button
            variant="primary"
            size="sm"
            @click="onSelectProject(row.item.id)"
            >{{ $t("Select") }}</b-button
          >
          <b-button variant="primary" size="sm" @click="onCopy(row.item.id)">{{
            $t("Copy")
          }}</b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="deleteProject(row.item.id)"
            >{{ $t("Delete") }}</b-button
          >
        </b-button-group>
      </template>
    </b-table>
  </b-modal>
</template>

<script lang="ts">
import SiteSpinner from "@/components/layout/SiteSpinner.vue";
import { Project } from "@/model/project/project";
import BootstrapHelpers from "@/mixins/BootstrapHelpers";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    SiteSpinner,
  },
  mixins: [BootstrapHelpers],
  data() {
    return {
      filter: "",
      fields: [
        {
          key: "id",
          label: this.$t("Project ID"),
          thClass: "project-column-width-id",
          tdClass: "project-column-width-id",
        },
        {
          key: "name",
          label: this.$t("Project Name"),
          thClass: "project-column-width",
          tdClass: "project-column-width",
        },
        {
          key: "customer",
          label: this.$t("Customer"),
          thClass: "project-column-width",
          tdClass: "project-column-width",
        },
        {
          key: "dateCreated",
          label: this.$t("Created"),
          thClass: "project-column-width-date",
          tdClass: "project-column-width-date",
        },
        { key: "action", label: "" },
      ],
    };
  },
  computed: {
    rows() {
      return 30;
    },
    getProjects(): Project[] {
      return this.$store.getters["Project/getProjects"];
    },
    isLoading(): boolean {
      return this.$store.getters["Project/isLoading"];
    },
  },
  methods: {
    onSelectProject(projectId: number) {
      const currentProjectId = Number(this.$route.query.project);

      if (currentProjectId !== projectId) {
        this.selectProject(projectId);
        this.$router.push({ query: { project: projectId.toString() } });
      }
      if (this.bvModal) {
        this.bvModal.hide("projects");
      }
    },
    onCopy(projectId: number) {
      this.copy(projectId)
        .then((project) =>
          this.$router.push({ query: { project: project.id?.toString() } }),
        )
        .then(() => this.bvModal && this.bvModal.hide("projects"));
    },
    selectProject(projectId: number): Promise<void> {
      return this.$store.dispatch("Project/selectProject", projectId);
    },
    copy(projectId: number): Promise<Project> {
      return this.$store.dispatch("Project/copy", projectId);
    },
    deleteProject(projectId: number): Promise<void> {
      return this.$store.dispatch("Project/deleteProject", projectId);
    },
  },
});
</script>

<style lang="scss">
.project-column-width {
  width: 30%;
}

.project-column-width-date {
  width: 50%;
}

.project-column-width-id,
.project-column-width-belt-type {
  width: 20%;
  text-align: center;
}
</style>
