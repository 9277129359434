import { catchError, urlPrefix, config, axios } from "./base-api";

import { Statistic } from "@/model/statistic/statistic";
import { StatisticBeltCount } from "@/model/statistic/statistic-belt-count";
import { StatisticBeltCountUser } from "@/model/statistic/statistic-belt-count-user";
import { StatisticYearMonth } from "@/model/statistic/statistic-year-month";

const urlBaseData = `${urlPrefix}/statistic`;

export default {
  async beltCountList(): Promise<Statistic<StatisticBeltCount>> {
    return await axios
      .get(`${urlBaseData}/count-list`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async projectYearCreated(): Promise<StatisticYearMonth> {
    return await axios
      .get(`${urlBaseData}/year-created`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async beltsByUser(): Promise<StatisticBeltCountUser[]> {
    return await axios
      .get(`${urlBaseData}/user`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
};
