import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { endLoadingBar, startLoadingBar } from "../helpers/global.helper";
import { BaseDataApi } from "@/api";
import { Language } from "@/model/language";
import { StorageUtils } from "@/utils/storage-utils";

@Module({ namespaced: true })
class BaseData extends VuexModule {
  public languages: Language[] = [];

  get getLanguage(): Language[] {
    return this.languages;
  }

  @Mutation
  public setLanguage(languages: Language[]): void {
    StorageUtils.setData("languages", languages);
    this.languages = languages;
  }

  @Action
  public fetchLanguage(): Promise<void> | void {
    if (StorageUtils.dataExists("languages")) {
      this.context.commit("setLanguage", StorageUtils.getData("languages"));
    } else {
      startLoadingBar();
      return BaseDataApi.language()
        .then((languages) => this.context.commit("setLanguage", languages))
        .finally(() => endLoadingBar());
    }
  }
}
export default BaseData;
