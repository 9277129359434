import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { RollerDriveBeltApi } from "@/api";
import { BaseData } from "@/model/roller-drive/base-data";
import { BeltField } from "@/model/belt-field";
import { CBeta } from "@/model/roller-drive/c-beta";
import { Pressure } from "@/model/roller-drive/pressure";
import { PulleySurface } from "@/model/roller-drive/pulley-surface";
import { RollerDiameter } from "@/model/roller-drive/roller-diameter";
import { RollerLength } from "@/model/roller-drive/roller-length";
import { RollerMaterial } from "@/model/roller-drive/roller-material";
import { TypeDrive } from "@/model/type-drive";
import { TypeIncline } from "@/model/roller-drive/type-incline";
import { TransportedGood } from "@/model/roller-drive/transported-good";
import { endLoadingBar, startLoadingBar } from "@/store/helpers/global.helper";
import { StorageUtils } from "@/utils/storage-utils";

@Module({ namespaced: true })
class RollerDriveBeltBaseDataStore extends VuexModule {
  public fields: BeltField[] = [];
  public typeDrive: TypeDrive[] = [];
  public transportedGood: TransportedGood[] = [];
  public rollerDiameter: RollerDiameter[] = [];
  public rollerLength: RollerLength[] = [];
  public rollerMaterial: RollerMaterial[] = [];
  public pulleySurface: PulleySurface[] = [];
  public cBeta: CBeta[] = [];
  public pressure: Pressure[] = [];
  public typeIncline: TypeIncline[] = [];

  get isLoaded(): boolean {
    return (
      this.fields.length > 0 &&
      this.typeDrive.length > 0 &&
      this.transportedGood.length > 0 &&
      this.rollerDiameter.length > 0 &&
      this.rollerLength.length > 0 &&
      this.rollerMaterial.length > 0 &&
      this.pulleySurface.length > 0 &&
      this.cBeta.length > 0 &&
      this.pressure.length > 0 &&
      this.typeIncline.length > 0
    );
  }

  get getFields(): BeltField[] {
    return this.fields;
  }

  get getTypeDrive(): TypeDrive[] {
    return this.typeDrive;
  }

  get getTransportedGood(): TransportedGood[] {
    return this.transportedGood;
  }

  get getRollerDiameter(): RollerDiameter[] {
    return this.rollerDiameter;
  }

  get getRollerLength(): RollerLength[] {
    return this.rollerLength;
  }

  get getRollerMaterial(): RollerMaterial[] {
    return this.rollerMaterial;
  }

  get getPulleySurface(): PulleySurface[] {
    return this.pulleySurface;
  }

  get getCBeta(): CBeta[] {
    return this.cBeta;
  }

  get getPressure(): Pressure[] {
    return this.pressure;
  }

  get getTypeIncline(): TypeIncline[] {
    return this.typeIncline;
  }

  @Mutation
  public setFields(fields: BeltField[]): void {
    this.fields = fields;
  }

  @Mutation
  public setTypeDrive(typeDrive: TypeDrive[]): void {
    this.typeDrive = typeDrive;
  }

  @Mutation
  public setTransportedGood(transportedGood: TransportedGood[]): void {
    this.transportedGood = transportedGood;
  }

  @Mutation
  public setRollerDiameter(rollerDiameter: RollerDiameter[]): void {
    this.rollerDiameter = rollerDiameter;
  }

  @Mutation
  public setRollerLength(rollerLength: RollerLength[]): void {
    this.rollerLength = rollerLength;
  }

  @Mutation
  public setRollerMaterial(rollerMaterial: RollerMaterial[]): void {
    this.rollerMaterial = rollerMaterial;
  }

  @Mutation
  public setPulleySurface(pulleySurface: PulleySurface[]): void {
    this.pulleySurface = pulleySurface;
  }

  @Mutation
  public setCBeta(cBeta: CBeta[]): void {
    this.cBeta = cBeta;
  }

  @Mutation
  public setPressure(pressure: Pressure[]): void {
    this.pressure = pressure;
  }

  @Mutation
  public setTypeIncline(typeIncline: TypeIncline[]): void {
    this.typeIncline = typeIncline;
  }

  @Action({ rawError: true })
  public async baseData(): Promise<void> {
    if (StorageUtils.dataExists("tb-data")) {
      const data = StorageUtils.getData("tb-data");
      this.context.commit("setFields", data.fields);
      this.context.commit("setTypeDrive", data.typeDrive);
      this.context.commit("setTransportedGood", data.transportedGood);
      this.context.commit("setRollerDiameter", data.rollerDiameter);
      this.context.commit("setRollerLength", data.rollerLength);
      this.context.commit("setRollerMaterial", data.rollerMaterial);
      this.context.commit("setPulleySurface", data.pulleySurface);
      this.context.commit("setCBeta", data.cBeta);
      this.context.commit("setPressure", data.pressure);
      this.context.commit("setTypeIncline", data.typeIncline);
    } else {
      startLoadingBar();
      await RollerDriveBeltApi.baseData()
        .then((data: BaseData) => {
          this.context.commit("setFields", data.fields);
          this.context.commit("setTypeDrive", data.typeDrive);
          this.context.commit("setTransportedGood", data.transportedGood);
          this.context.commit("setRollerDiameter", data.rollerDiameter);
          this.context.commit("setRollerLength", data.rollerLength);
          this.context.commit("setRollerMaterial", data.rollerMaterial);
          this.context.commit("setPulleySurface", data.pulleySurface);
          this.context.commit("setCBeta", data.cBeta);
          this.context.commit("setPressure", data.pressure);
          this.context.commit("setTypeIncline", data.typeIncline);
          StorageUtils.setData("tb-data", data);
        })
        .finally(() => endLoadingBar());
    }
  }
}
export default RollerDriveBeltBaseDataStore;
