import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { Notification } from "@/model/notification/notification";
import { NotificationType } from "@/model/notification/notification-type";

@Module({ namespaced: true })
class NotificationStore extends VuexModule {
  private notification: Notification | undefined;

  get getNotification() {
    return this.notification;
  }

  @Mutation
  public setNotification(notification: Notification): void {
    this.notification = notification;
  }

  @Action({ rawError: true })
  public error(messages: string): void {
    if (messages) {
      this.context.commit("setNotification", {
        messages,
        type: NotificationType.ERROR,
      });
    }
  }

  @Action({ rawError: true })
  public info(messages: string): void {
    if (messages) {
      this.context.commit("setNotification", {
        messages,
        type: NotificationType.INFO,
      });
    }
  }

  @Action({ rawError: true })
  public success(messages: string): void {
    if (messages) {
      this.context.commit("setNotification", {
        messages,
        type: NotificationType.SUCCESS,
      });
    }
  }

  @Action({ rawError: true })
  public warning(messages: string): void {
    if (messages) {
      this.context.commit("setNotification", {
        messages,
        type: NotificationType.WARNING,
      });
    }
  }
}
export default NotificationStore;
