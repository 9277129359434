import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { RollerDriveSummary } from "@/model/roller-drive/roller-drive-summary";

@Module({ namespaced: true })
class RollerDriveBeltSummaryStore extends VuexModule {
  public summary: RollerDriveSummary | undefined = undefined;

  get getSummary(): RollerDriveSummary | undefined {
    return this.summary;
  }

  @Mutation
  public setSummary(summary: RollerDriveSummary): void {
    this.summary = summary;
  }
}
export default RollerDriveBeltSummaryStore;
