<template>
  <b-modal
    id="documentation"
    size="lg"
    header-bg-variant="primary"
    header-text-variant="white"
    footer-bg-variant="light"
    footer-text-variant="white"
    body-bg-variant="light"
    ok-only
    ok-variant="secondary"
    scrollable
    :title="$t('Documentation')"
    :ok-title="$t('Close')"
  >
    <b-row>
      <b-col cols="12" sm="6">
        <h5>{{ $t("Questionnaire") }}</h5>
        <!-- <site-spinner class="spinner" v-if="isLoading" /> -->
        <div class="d-flex flex-column">
          <div
            class="d-flex flex-column pt-3"
            v-if="questionLinks.some((l) => l.link[$i18n.locale])"
          >
            <h6 v-if="getLanguage.length > 0">
              {{ getLanguage.find((l) => l.code === $i18n.locale).name }}
            </h6>
            <template v-for="(l, index) in questionLinks">
              <b-link
                v-if="l.link[$i18n.locale]"
                :key="index"
                @click="download(l.name, l.link[$i18n.locale])"
              >
                <font-awesome-icon class="fa-lg" :icon="['far', 'file-word']" />
                {{ l.label }} ({{ $i18n.locale }})
              </b-link>
            </template>
          </div>
          <div class="d-flex flex-column pt-3" v-if="$i18n.locale !== 'en'">
            <h6 v-if="getLanguage.length > 0">
              {{ getLanguage.find((l) => l.code === "en").name }}
            </h6>
            <b-link
              v-for="(l, index) in questionLinks"
              :key="'en_' + index"
              @click="download(l.name, l.link['en'])"
            >
              <font-awesome-icon class="fa-lg" :icon="['far', 'file-word']" />
              {{ l.label }} (en)
            </b-link>
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="6">
        <h5 class="mt-2 mt-sm-0">{{ $t("User Manual") }}</h5>
        <div class="d-flex flex-column">
          <b-link
            @click="
              download('User-Manual.pdf', 'RappCalc_Online_User-Manual.pdf')
            "
          >
            <font-awesome-icon class="fa-lg" :icon="['far', 'file-pdf']" />
            {{ $t("Download") }}
          </b-link>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script lang="ts">
import { BaseDataApi } from "@/api";
import { Language } from "@/model/language";
import { endLoadingBar, startLoadingBar } from "@/store/helpers/global.helper";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      isLoading: false,
      questionLinks: [
        {
          label: this.$t("Power Transmission Belts"),
          name: "Questionnaire_PowerTransmission.docx",
          link: {
            en: "Questionnaire_PowerTransmission_EN.docx",
            de: "Questionnaire_PowerTransmission_DE.docx",
            fr: "Questionnaire_PowerTransmission_FR.docx",
            it: "Questionnaire_PowerTransmission_IT.docx",
            es: "Questionnaire_PowerTransmission_ES.docx",
            cn: "Questionnaire_PowerTransmission_CN.docx",
          },
        },
        {
          label: this.$t("Tangential Belts"),
          name: "Questionnaire_TangentialBelts.docx",
          link: {
            en: "Questionnaire_TangentialBelts_EN.docx",
            de: "Questionnaire_TangentialBelts_DE.docx",
            fr: "Questionnaire_TangentialBelts_FR.docx",
            it: "Questionnaire_TangentialBelts_IT.docx",
            es: "Questionnaire_TangentialBelts_ES.docx",
            cn: "Questionnaire_TangentialBelts_CN.docx",
          },
        },
        {
          label: this.$t("Roller Drive Belts"),
          name: "Questionnaire_RollerDrive.docx",
          link: {
            en: "Questionnaire_RollerDrive_EN.docx",
            de: "Questionnaire_RollerDrive_DE.docx",
            fr: "Questionnaire_RollerDrive_FR.docx",
            it: "Questionnaire_RollerDrive_IT.docx",
            es: "Questionnaire_RollerDrive_ES.docx",
            ru: "Questionnaire_RollerDrive_CN.docx",
          },
        },
      ],
    };
  },
  methods: {
    download(name: string, link: string) {
      startLoadingBar();
      BaseDataApi.downloadQuestionnaire(name, link).finally(() =>
        endLoadingBar(),
      );
    },
  },
  computed: {
    getLanguage(): Language[] {
      return this.$store.getters["BaseData/getLanguage"];
    },
  },
});
</script>
