import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-column pt-3"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "d-flex flex-column pt-3"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "mt-2 mt-sm-0" }
const _hoisted_7 = { class: "d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_link = _resolveComponent("b-link")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    id: "documentation",
    size: "lg",
    "header-bg-variant": "primary",
    "header-text-variant": "white",
    "footer-bg-variant": "light",
    "footer-text-variant": "white",
    "body-bg-variant": "light",
    "ok-only": "",
    "ok-variant": "secondary",
    scrollable: "",
    title: _ctx.$t('Documentation'),
    "ok-title": _ctx.$t('Close')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "6"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h5", null, _toDisplayString(_ctx.$t("Questionnaire")), 1),
              _createElementVNode("div", _hoisted_1, [
                (_ctx.questionLinks.some((l) => l.link[_ctx.$i18n.locale]))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_ctx.getLanguage.length > 0)
                        ? (_openBlock(), _createElementBlock("h6", _hoisted_3, _toDisplayString(_ctx.getLanguage.find((l) => l.code === _ctx.$i18n.locale).name), 1))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionLinks, (l, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (l.link[_ctx.$i18n.locale])
                            ? (_openBlock(), _createBlock(_component_b_link, {
                                key: index,
                                onClick: ($event: any) => (_ctx.download(l.name, l.link[_ctx.$i18n.locale]))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_font_awesome_icon, {
                                    class: "fa-lg",
                                    icon: ['far', 'file-word']
                                  }),
                                  _createTextVNode(" " + _toDisplayString(l.label) + " (" + _toDisplayString(_ctx.$i18n.locale) + ") ", 1)
                                ], undefined, true),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$i18n.locale !== 'en')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      (_ctx.getLanguage.length > 0)
                        ? (_openBlock(), _createElementBlock("h6", _hoisted_5, _toDisplayString(_ctx.getLanguage.find((l) => l.code === "en").name), 1))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionLinks, (l, index) => {
                        return (_openBlock(), _createBlock(_component_b_link, {
                          key: 'en_' + index,
                          onClick: ($event: any) => (_ctx.download(l.name, l.link['en']))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              class: "fa-lg",
                              icon: ['far', 'file-word']
                            }),
                            _createTextVNode(" " + _toDisplayString(l.label) + " (en) ", 1)
                          ], undefined, true),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "12",
            sm: "6"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.$t("User Manual")), 1),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_b_link, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.download('User-Manual.pdf', 'RappCalc_Online_User-Manual.pdf')
            ))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      class: "fa-lg",
                      icon: ['far', 'file-pdf']
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("Download")), 1)
                  ], undefined, true),
                  _: 1
                })
              ])
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      })
    ], undefined, true),
    _: 1
  }, 8, ["title", "ok-title"]))
}