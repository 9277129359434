<template>
  <div
    class="site-spinner-container d-flex flex-row justify-content-between align-items-center"
  >
    <b-spinner variant="primary" :label="$t('Loading') + '...'"></b-spinner>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
@import "@/scss/custom-bootstrap.scss";
.site-spinner-container {
  height: 55vh;
  width: 100%;

  .spinner-border {
    margin: auto;
    height: 150px;
    width: 150px;
  }
}
</style>
