<template>
  <b-modal
    id="sort-of-tangential-drive-modal"
    size="xl"
    header-bg-variant="primary"
    header-text-variant="white"
    footer-bg-variant="light"
    footer-text-variant="white"
    body-bg-variant="white"
    body-class="modal-body-no-spacing"
    ok-only
    ok-variant="secondary"
    scrollable
    v-model="show"
    :title="$t('Sketches')"
    :ok-title="$t('Close')"
  >
    <b-card-body v-if="getParams">
      <b-row class="align-self-stretch" cols="2" align-v="stretch">
        <b-col
          class="align-items-stretch p-2"
          v-for="drive in getParams.sod.allowedValues"
          :key="drive"
        >
          <b-card
            class="h-100"
            align="center"
            img-bottom
            :header-bg-variant="
              getParams.sod.value === drive ? 'primary' : 'light'
            "
            :header-text-variant="
              getParams.sod.value === drive ? 'white' : 'black'
            "
            :border-variant="
              getParams.sod.value === drive ? 'primary' : 'light'
            "
            @click="
              inputSelect(drive, 'sod');
              show = false;
            "
          >
            <template #header>
              <font-awesome-icon
                class="check-card-symbol"
                size="lg"
                v-if="getParams.sod.value !== drive"
                :icon="['far', 'circle']"
              />
              <font-awesome-icon
                class="check-card-symbol"
                size="lg"
                v-if="getParams.sod.value === drive"
                :icon="['far', 'check-circle']"
              />
              Drive {{ drive }}
            </template>
            <b-card-text>
              <img
                class="img-fluid"
                v-if="getImage(drive)"
                :src="getImage(drive)"
              />
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card-body>
  </b-modal>
</template>

<script lang="ts">
import BaseDataMixin from "@/mixins/TangentialBeltBaseData";
import { TangentialBeltParams } from "@/model/tangential-belt/tangential-belt-params";
import { TangentialDrive } from "@/model/tangential-belt/tangential-drive";
import { getSketchPath } from "@/utils/common-utils";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [BaseDataMixin],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    getImage(id: number) {
      const drive = this.getTangentialDrive.find((drive) => drive.id === id);
      const filename = drive?.name.toLowerCase().replace(/ /g, "_");
      return getSketchPath(`${filename}.png`);
    },
  },
  computed: {
    getTangentialDrive(): TangentialDrive[] {
      return this.$store.getters["TangentialBeltBaseData/getTangentialDrive"];
    },
    getParams(): TangentialBeltParams {
      return this.$store.getters["TangentialBeltParams/getParams"];
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/custom-bootstrap";
.check-card-symbol {
  position: absolute;
  top: 5px;
  left: 5px;
}

.drive-image {
  padding: 5px;
}

.drive-image-selected {
  border: 3px solid rgba($info, 0.2);
}

.drive-image:hover {
  background-color: rgba($info, 0.2);
}
</style>
