import { BeltField } from "@/model/belt-field";
import { BeltParam } from "@/model/belt-param";
import { KeyValue } from "@/model/key-value";
import { TangentialBeltParams } from "@/model/tangential-belt/tangential-belt-params";
import { decimal } from "@/utils/filter-utils";
import { defineComponent } from "vue";

// const notificationStore = namespace("Notification");
// const baseDataStore = namespace("TangentialBeltBaseData");
// const beltParamsStore = namespace("TangentialBeltParams");

const TangentialBeltBaseDataMixin = defineComponent({
  methods: {
    getFieldName(key: string) {
      const name = this.getFields.find((f) => f.key === key)?.name;

      if (name) {
        return this.$t(name);
      }

      return name;
    },
    getFieldUnit(key: string) {
      return this.getFields.find((f) => f.key === key)?.unit;
    },
    getFieldDecimals(key: string) {
      return this.getFields.find((f) => f.key === key)?.decimals;
    },
    getFieldMin(key: string) {
      return this.getFields.find((f) => f.key === key)?.range.min;
    },
    getFieldMax(key: string) {
      return this.getFields.find((f) => f.key === key)?.range.max;
    },
    inputSelect(value: number, key: string) {
      this.updateValueOfItem({ key, value });
      this.tangentialCalculate();
    },
    input(value: number, key: string, rules: string[], param: BeltParam) {
      const newValue = Number(value);
      const actualValue = decimal(
        Number(this.getParams[key].value),
        Number(this.getFieldDecimals(key)),
      );

      if (actualValue === newValue) {
        return;
      }

      if (!value) {
        this.removeParam(key);
        this.tangentialCalculate();
        return;
      }

      param.valid = true;

      if (rules.includes("range")) {
        const min = this.getFieldMin(key) ?? 0;
        const max = this.getFieldMax(key) ?? 0;

        if (!(newValue >= min && newValue <= max)) {
          param.valid = false;
          this.warning(`The value is outside the range of ${min} and ${max}`);
        }
      }

      if (!param.valid) {
        return;
      }

      this.updateValueOfItem({ key, value: newValue });
      this.tangentialCalculate();
    },
    warning(messages: string): Promise<void> {
      return this.$store.dispatch("Notification/warning", messages);
    },
    tangentialCalculate(): Promise<void> {
      return this.$store.dispatch("TangentialBeltParams/tangentialCalculate");
    },
    updateValueOfItem(data: KeyValue): Promise<void> {
      return this.$store.dispatch(
        "TangentialBeltParams/updateValueOfItem",
        data,
      );
    },
    removeParam(key: string): Promise<void> {
      return this.$store.dispatch("TangentialBeltParams/removeParam", key);
    },
  },
  computed: {
    getFields(): BeltField[] {
      return this.$store.getters["TangentialBeltBaseData/getFields"];
    },
    getParams(): TangentialBeltParams {
      return this.$store.getters["TangentialBeltParams/getParams"];
    },
  },
});
export default TangentialBeltBaseDataMixin;
