<template>
  <span></span>
</template>

<script lang="ts">
import { Notification } from "@/model/notification/notification";
import { NotificationType } from "@/model/notification/notification-type";
import BootstrapHelpers from "@/mixins/BootstrapHelpers";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [BootstrapHelpers],
  created(): void {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "Notification/setNotification") {
        const notification: Notification = state.Notification.notification;

        if (Array.isArray(notification.messages)) {
          notification.messages.forEach((message) =>
            this.setToast(message, notification.type),
          );
        } else {
          this.setToast(notification.messages, notification.type);
        }
      }
    });
  },
  methods: {
    setToast(message: string, type: NotificationType) {
      const msgContainer = message.split("@@PARAM");
      const msg = this.$t(`${msgContainer[0]}`);
      if (this.bvToast) {
        this.bvToast.toast(
          `${msg.toString()}${msgContainer.length > 1 ? msgContainer[1] : ""}`,
          {
            variant: type,
            solid: true,
          },
        );
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
