import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HeartbeatApi from '@/api/server/heartbeat-api'

const signin_route_name = "SignIn"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/sign-in",
    name: signin_route_name,
    component: () => import("@/views/SignIn.vue"),
    meta: { isSignIn: true },
  },
  {
    path: "/power-transmision",
    name: "PowerTransmission",
    component: () => import("@/views/power-transmission/index.vue"),
    meta: { beltType: "fb" },
  },
  {
    path: "/tangential-belts",
    name: "TangentialBelts",
    component: () => import("@/views/tangential-belt/index.vue"),
    meta: { beltType: "tb" },
  },
  {
    path: "/roller-drive-belts",
    name: "RollerDriveBelts",
    component: () => import("@/views/roller-drive/index.vue"),
    meta: { beltType: "rb" },
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: () => import("@/views/Statistic.vue"),
  },
  { path: "/", redirect: { name: "PowerTransmission" } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  if (to.name !== signin_route_name && from.name !== signin_route_name) {
    const isAuthenticated = await HeartbeatApi.ping()
    if (!isAuthenticated) {
      return { name: signin_route_name }
    }
  }
})

export default router;
