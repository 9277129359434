import axios, { AxiosError, AxiosResponse } from "axios";

import { dateTimeFile } from "@/plugins/date-fns";
import router from "@/router";
import { errorNotify } from "@/store/helpers/global.helper";
import { StorageUtils } from "@/utils/storage-utils";

export { axios, AxiosError, AxiosResponse, router };

export const urlPrefix = "/api";

export const config = {
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

function getErrorMessage(response: AxiosResponse) {
  if (response.data && response.data.error) {
    return response.data.error;
  }

  if (response.data && response.data.detail) {
    return response.data.detail;
  }

  if (response.data && response.data.title) {
    return response.data.title;
  }

  if (response.data) {
    if (response.data instanceof ArrayBuffer) {
      const enc = new TextDecoder("utf-8");
      return enc.decode(response.data);
    }
    return response.data as string;
  }

  return "No valid error message available. Please contact support";
}

export const catchError = (error: AxiosError): Promise<void> | undefined => {
  if (error.response) {
    if (error.response.status === 401) {
      if (router.currentRoute.value.path !== "/sign-in") {
        router.push({ name: "SignIn" });
        StorageUtils.clearAll();
        return Promise.reject() as Promise<void>;
      } else {
        return;
      }
    }

    if (error.response.status === 500) {
      errorNotify(getErrorMessage(error.response));
      return Promise.reject() as Promise<void>;
    }

    return Promise.reject(new Error(getErrorMessage(error.response)));
  }
  return Promise.reject(new Error(error.message));
};

export const downloadBeltDocument = (
  response: AxiosResponse,
  fileName: string,
) => {
  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: "application/pdf" }),
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `${fileName}-${dateTimeFile(new Date().toISOString())}.pdf`,
  ); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const getCalculationQuery = (
  beltId: string | undefined,
  projectId: number | undefined,
): string => {
  let query = "";

  if (beltId) {
    query = `beltId=${beltId}`;
  }

  if (projectId) {
    query = `${query ? query + "&" : ""}projectId=${projectId}`;
  }

  return query;
};
