<template>
  <b-modal
    id="user-settings"
    size="lg"
    header-bg-variant="primary"
    header-text-variant="white"
    footer-bg-variant="light"
    footer-text-variant="white"
    body-bg-variant="light"
    ok-only
    ok-variant="secondary"
    scrollable
    v-model="show"
    :ok-title="$t('Close')"
    :title="$t('User Settings')"
  >
    <template v-slot:default>
      <b-form ref="form" v-if="!isLoading">
        <b-row class="align-items-center my-1">
          <b-col sm="3">
            <label for="language">{{ $t(`Language`) }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-select
              id="language"
              value-field="code"
              text-field="name"
              v-model="getUser.langCode"
              :options="language"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="align-items-center my-1">
          <b-col sm="3">
            <label for="name">{{ $t(`Name`) }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="name"
              type="text"
              v-model="getUser.name"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center my-1">
          <b-col sm="3">
            <label for="user-email">{{ $t(`E-Mail`) }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="user-email"
              type="email"
              v-model="getUser.email"
              :state="onCheckEmail(getUser.email)"
            ></b-form-input>
            <b-form-invalid-feedback id="user-email-feedback">
              {{ $t("E-Mail is not valid") }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="align-items-center my-1">
          <b-col sm="3">
            <label for="address">{{ $t(`Address`) }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="address"
              type="text"
              v-model="getUser.address"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center my-1">
          <b-col sm="3">
            <label for="zipcode">{{ $t(`Zip / City`) }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="zipcode"
              type="text"
              v-model="getUser.zipCity"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center my-1">
          <b-col sm="3">
            <label for="country">{{ $t(`Country`) }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              id="country"
              type="text"
              v-model="getUser.country"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center my-1">
          <b-col sm="3">
            <label for="note">{{ $t(`Note`) }}</label>
          </b-col>
          <b-col sm="9">
            <b-form-textarea
              id="note"
              v-model="getUser.note"
              rows="3"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row class="align-items-center my-1">
          <b-col offset-md="3">
            <b-form-checkbox
              id="auto-save"
              name="auto-save"
              v-model="getUser.autosave"
              >{{ $t(`Save changes automatically`) }}</b-form-checkbox
            >
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template v-slot:modal-footer>
      <div
        class="w-100 d-flex flex-row justify-content-between align-items-center"
      >
        <b-button variant="primary" @click="onSave()">{{
          $t("Save")
        }}</b-button>
        <b-button variant="secondary" class="float-right" @click="onReset()">
          {{ $t("Close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Language } from "@/model/language";
import { User } from "@/model/user/user";
import { loadLanguageAsync } from "@/plugins/i18n";
import { checkEmail } from "@/utils/common-utils";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      show: false,
    };
  },
  computed: {
    language() {
      // eslint-disable-next-line
            const language: any[] = [];
      this.getLanguage.forEach((l) =>
        language.push({ code: l.code, name: this.$t(l.name) }),
      );
      return language;
    },
    getUser(): User {
      return this.$store.getters["User/getUser"];
    },
    isLoading(): boolean {
      return this.$store.getters["User/isLoading"];
    },
    getLanguage(): Language[] {
      return this.$store.getters["BaseData/getLanguage"];
    },
  },
  created() {
    this.fetchLanguage();
  },
  methods: {
    onSave() {
      // eslint-disable-next-line
            if (!(this.$refs.form as any).checkValidity()) {
        this.error("Form not filled out correctly");
        return;
      }
      this.updateLanguage();
      this.updateUser();
      this.show = false;
    },
    onReset() {
      this.resetUser();
      this.show = false;
    },
    updateLanguage() {
      loadLanguageAsync(this.getUser.langCode);
    },
    onCheckEmail(email: string) {
      return checkEmail(email);
    },
    error(messages: string): Promise<void> {
      return this.$store.dispatch("Notification/error", messages);
    },
    updateUser(): Promise<void> {
      return this.$store.dispatch("User/updateUser");
    },
    resetUser(): Promise<void> {
      return this.$store.dispatch("User/resetUser");
    },
    fetchLanguage(): Promise<void> {
      return this.$store.dispatch("BaseData/fetchLanguage");
    },
  },
});
</script>

<style lang="scss" scoped>
.full-size {
  width: 100%;
}
</style>
