import { createI18n } from "vue-i18n";

import axios from "axios";

import { BaseDataApi } from "@/api/index";
import { Translation } from "@/model/translation";
import { endLoadingBar, startLoadingBar } from "@/store/helpers/global.helper";
import { StorageUtils } from "@/utils/storage-utils";

export const i18n = createI18n({
  locale: "en", // set locale
  fallbackLocale: "en",
  legacy: false,
});

const loadedLanguages: string[] = []; // our default language that is preloaded

const setI18nLanguage = (lang: string) => {
  i18n.global.locale.value = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html")?.setAttribute("lang", lang);
  return lang;
};

const setLanguage = (lang: string, tranlation: Translation) => {
  loadedLanguages.push(lang);
  i18n.global.setLocaleMessage(lang, tranlation);
  setI18nLanguage(lang);
};

export function isLanguageLoaded(): boolean {
  return loadedLanguages.length > 0;
}

export async function loadLanguageAsync(lang: string) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.global.locale.value !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }

  if (StorageUtils.dataExists(lang)) {
    const data = StorageUtils.getData(lang);
    setLanguage(lang, data);
  } else {
    startLoadingBar();
    return BaseDataApi.translation(lang)
      .then((tranlation) => {
        setLanguage(lang, tranlation);
        StorageUtils.setData(lang, tranlation);
      })
      .finally(() => endLoadingBar());
  }
}
