import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { UnsuitableBelt } from "@/model/unsuitable-belt";

@Module({ namespaced: true })
class UnsuitableBeltStore extends VuexModule {
  public belts: UnsuitableBelt[] = [];

  get getBelts(): UnsuitableBelt[] {
    return this.belts;
  }

  @Mutation
  public setBelts(belts: UnsuitableBelt[]): void {
    this.belts = belts;
  }

  @Action
  public initialUnsuitableBelts() {
    this.context.commit("setBelts", []);
  }
}
export default UnsuitableBeltStore;
