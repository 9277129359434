import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class HeaderLoadingBar extends VuexModule {
  public loadState: string[] = [];

  get isBarLoading(): string {
    return this.loadState[this.loadState.length - 1] ?? "";
  }

  @Mutation
  public setLoadState(state: string): void {
    this.loadState.push(state);
  }

  @Mutation
  public removeLoadState(): void {
    this.loadState.pop();
  }

  @Action
  public startLoading(): void {
    this.context.commit("setLoadState", "fade");
  }

  @Action
  public stopLoad(): void {
    this.context.commit("removeLoadState");
  }
}
export default HeaderLoadingBar;
