import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faChevronDown,
  faChevronUp,
  faCog,
  faExclamationTriangle,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  faQuestionCircle as farQuestionCircle,
  faFilePdf,
  faFileWord,
  faCircle,
  faCheckCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCog,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFilePdf,
  faFileWord,
  faPrint,
  farQuestionCircle,
);

export default (app: any) => {
  app.component("font-awesome-icon", FontAwesomeIcon);
};
