import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_card_body = _resolveComponent("b-card-body")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    id: "sort-of-tangential-drive-modal",
    size: "xl",
    "header-bg-variant": "primary",
    "header-text-variant": "white",
    "footer-bg-variant": "light",
    "footer-text-variant": "white",
    "body-bg-variant": "white",
    "body-class": "modal-body-no-spacing",
    "ok-only": "",
    "ok-variant": "secondary",
    scrollable: "",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
    title: _ctx.$t('Sketches'),
    "ok-title": _ctx.$t('Close')
  }, {
    default: _withCtx(() => [
      (_ctx.getParams)
        ? (_openBlock(), _createBlock(_component_b_card_body, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_b_row, {
                class: "align-self-stretch",
                cols: "2",
                "align-v": "stretch"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getParams.sod.allowedValues, (drive) => {
                    return (_openBlock(), _createBlock(_component_b_col, {
                      class: "align-items-stretch p-2",
                      key: drive
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_card, {
                          class: "h-100",
                          align: "center",
                          "img-bottom": "",
                          "header-bg-variant": 
              _ctx.getParams.sod.value === drive ? 'primary' : 'light'
            ,
                          "header-text-variant": 
              _ctx.getParams.sod.value === drive ? 'white' : 'black'
            ,
                          "border-variant": 
              _ctx.getParams.sod.value === drive ? 'primary' : 'light'
            ,
                          onClick: ($event: any) => {
              _ctx.inputSelect(drive, 'sod');
              _ctx.show = false;
            }
                        }, {
                          header: _withCtx(() => [
                            (_ctx.getParams.sod.value !== drive)
                              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                  key: 0,
                                  class: "check-card-symbol",
                                  size: "lg",
                                  icon: ['far', 'circle']
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.getParams.sod.value === drive)
                              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                  key: 1,
                                  class: "check-card-symbol",
                                  size: "lg",
                                  icon: ['far', 'check-circle']
                                }))
                              : _createCommentVNode("", true),
                            _createTextVNode(" Drive " + _toDisplayString(drive), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_b_card_text, null, {
                              default: _withCtx(() => [
                                (_ctx.getImage(drive))
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      class: "img-fluid",
                                      src: _ctx.getImage(drive)
                                    }, null, 8, _hoisted_1))
                                  : _createCommentVNode("", true)
                              ], undefined, true),
                              _: 2
                            }, 1024)
                          ], undefined, true),
                          _: 2
                        }, 1032, ["header-bg-variant", "header-text-variant", "border-variant", "onClick"])
                      ], undefined, true),
                      _: 2
                    }, 1024))
                  }), 128))
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], undefined, true),
    _: 1
  }, 8, ["modelValue", "title", "ok-title"]))
}