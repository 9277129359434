import deepcopy from "deepcopy";

import { FlatBeltParams } from "@/model/flat-belt/flat-belt-params";

const initialParams: FlatBeltParams = {
  b0min: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  dmax: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  dmin: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  b0minmax: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  z: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  d1: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  d2: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  l: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  e: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  beta: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  n1: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  n2: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  v: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  pm: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  pmhp: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  t1: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  t2: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  c: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  usrelong: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  usrelonge: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  b0: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  sod: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  cond: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  gg: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  gt: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  gr: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  lt: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  ll: {
    value: null,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
  isarcd1: {
    value: true,
    valid: true,
    calculated: false,
    disabled: false,
    message: null,
  },
};

export function getInitialParams(): FlatBeltParams {
  return deepcopy(initialParams);
}
