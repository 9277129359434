import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sr-only" }
const _hoisted_2 = { class: "sr-only" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_input_group_append = _resolveComponent("b-input-group-append")!
  const _component_b_input_group = _resolveComponent("b-input-group")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_card_body = _resolveComponent("b-card-body")!
  const _component_b_th = _resolveComponent("b-th")!
  const _component_b_tr = _resolveComponent("b-tr")!
  const _component_b_link = _resolveComponent("b-link")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    id: "factors-table",
    size: "lg",
    "header-bg-variant": "primary",
    "header-text-variant": "white",
    "footer-bg-variant": "light",
    "footer-text-variant": "white",
    "body-bg-variant": "light",
    "body-class": "modal-body-no-spacing",
    "ok-only": "",
    "ok-variant": "secondary",
    scrollable: "",
    title: _ctx.$t('Load Factors'),
    "ok-title": _ctx.$t('Close')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_card_body, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_row, { class: "justify-content-center align-items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { class: "my-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, {
                    label: _ctx.$t('Search'),
                    "label-cols-sm": "2",
                    "label-size": "sm",
                    "label-for": "filterInput",
                    class: "mb-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input_group, { size: "sm" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            modelValue: _ctx.filter,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
                            type: "search",
                            id: "filterInput"
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_b_input_group_append, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_button, {
                                disabled: !_ctx.filter,
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter = ''))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("Clear")), 1)
                                ], undefined, true),
                                _: 1
                              }, 8, ["disabled"])
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  }, 8, ["label"])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      }),
      _createVNode(_component_b_table, {
        class: "mb-0",
        striped: "",
        bordered: "",
        "sort-by": "description",
        filter: _ctx.filter,
        fields: _ctx.fields,
        items: _ctx.getLoads
      }, {
        "thead-top": _withCtx(() => [
          _createVNode(_component_b_tr, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_th, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("Sort of drive")), 1)
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_th, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("sortOfGeneralDrive")), 1)
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_th, {
                class: "text-center",
                colspan: "3",
                variant: "secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("3-phase AC motor")), 1)
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ]),
        "cell(description)": _withCtx((row) => [
          _createElementVNode("span", {
            innerHTML: row.value
          }, null, 8, _hoisted_3)
        ]),
        "cell(general)": _withCtx((row) => [
          (row.value)
            ? (_openBlock(), _createBlock(_component_b_link, {
                key: 0,
                onClick: ($event: any) => (_ctx.selectValue(row.value))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.value), 1)
                ], undefined, true),
                _: 2
              }, 1032, ["onClick"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, "-"))
        ]),
        "cell(smooth)": _withCtx((row) => [
          (row.value)
            ? (_openBlock(), _createBlock(_component_b_link, {
                key: 0,
                onClick: ($event: any) => (_ctx.selectValue(row.value))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.value), 1)
                ], undefined, true),
                _: 2
              }, 1032, ["onClick"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, "-"))
        ]),
        "cell(stuM)": _withCtx((row) => [
          (row.value)
            ? (_openBlock(), _createBlock(_component_b_link, {
                key: 0,
                onClick: ($event: any) => (_ctx.selectValue(row.value))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.value), 1)
                ], undefined, true),
                _: 2
              }, 1032, ["onClick"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, "-"))
        ]),
        _: 1
      }, 8, ["filter", "fields", "items"])
    ], undefined, true),
    _: 1
  }, 8, ["title", "ok-title"]))
}