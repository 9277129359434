import { catchError, config, axios } from "./base-api";

import { User } from "@/model/user/user";
import { UserCreate } from "@/model/user/user-create";

const urlBaseData = `/api/user`;

export default {
  async list(): Promise<User[]> {
    return await axios
      .get(`${urlBaseData}/admin`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async get(): Promise<User> {
    return await axios
      .get(`${urlBaseData}`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async create(user: UserCreate): Promise<User> {
    return await axios
      .post(`${urlBaseData}/admin`, user, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async update(userId: number, user: User): Promise<User> {
    return await axios
      .put(`${urlBaseData}/${userId}`, user, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async deleteAdmin(userId: number): Promise<User> {
    return await axios
      .delete(`${urlBaseData}/admin/${userId}`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async updatePassordAdmin(
    userId: number,
    password: string,
    isAdmin: boolean,
  ): Promise<User> {
    return await axios
      .put(`${urlBaseData}/${userId}/password`, { password, isAdmin }, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async updatePassordUser(userId: number, password: string): Promise<User> {
    return await axios
      .put(`${urlBaseData}/${userId}/password`, { password }, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async logout(): Promise<void> {
    return await axios
      .get(`api/logout`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
};
