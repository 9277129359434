<template>
  <header class="bg-light">
    <div class="p-1 p-sm-2 p-md-3 p-lg-4 px-lg-5">
      <b-row class="no-gutters justify-content-center align-items-center">
        <b-col cols="9" class="order-1 pr-0 pr-lg-4" sm="3">
          <img src="@/assets/images/rapplon_logo_RGB.png" class="img-fluid" />
        </b-col>
        <b-col cols="12" class="order-3 order-sm-2" sm="6">
          <nav
            class="nav nav-masthead d-flex flex-lg-row flex-column justify-content-center align-items-baseline"
          >
            <router-link
              class="nav-link m-auto"
              v-for="(nav, index) in navigation"
              :key="index"
              :to="{ name: nav.link }"
              >{{ $t(nav.label) }}</router-link
            >
            <div class="nav-link m-auto" to="" v-b-modal.documentation>
              {{ $t("Downloads") }}
            </div>
          </nav>
        </b-col>
        <b-col cols="3" class="order-2 order-sm-3" sm="3">
          <nav class="d-flex justify-content-end align-items-center">
            <b-dropdown
              id="setting-menu"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              right
            >
              <template v-slot:button-content>
                <font-awesome-icon icon="cog" size="2x" />
              </template>
              <b-dropdown-item
                v-b-modal.user-settings
                @click="fetchUser(false)"
              >
                {{ $t("Settings") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="getUser && getUser.isAdmin"
                v-b-modal.user-management
                @click="fetchUsers(true)"
                >{{ $t("Manage Users") }}</b-dropdown-item
              >
              <b-dropdown-item
                :to="{ name: 'Statistics' }"
                v-if="getUser && getUser.isAdmin"
                >{{ $t("Statistics") }}</b-dropdown-item
              >
              <b-dropdown-item v-b-modal.user-change-password>{{
                $t("Change password")
              }}</b-dropdown-item>
              <b-dropdown-item
                ><div class="dropdown-link" @click.prevent="logout()">
                  {{ $t("Logout") }}
                </div></b-dropdown-item
              >
            </b-dropdown>
          </nav>
        </b-col>
      </b-row>
    </div>
    <b-skeleton
      class="header-border-gradient"
      :class="{ 'no-loading': !isBarLoading }"
      :animation="isBarLoading"
      width="100%"
    ></b-skeleton>
  </header>
</template>

<script lang="ts">
import { UserApi } from "@/api";
import { StorageUtils } from "@/utils/storage-utils";
import { User } from "@/model/user/user";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      navigation: [
        { label: this.$t("Power Transmission"), link: "PowerTransmission" },
        { label: this.$t("Tangential Belts"), link: "TangentialBelts" },
        { label: this.$t("Roller Drive Belts"), link: "RollerDriveBelts" },
      ],
    };
  },
  methods: {
    logout() {
      UserApi.logout()
        .then(() => {
          StorageUtils.clearAll();
          this.$router.push({ name: "SignIn" });
        })
        .catch((err) => this.error(`Logout failed. Reason ${err}`));
    },
    error(messages: string): Promise<void> {
      return this.$store.dispatch("error", messages);
    },
    fetchUser(activateSiteSpinner: boolean): Promise<void> {
      return this.$store.dispatch("User/fetchUser", activateSiteSpinner);
    },
    fetchUsers(refresh: boolean): Promise<void> {
      return this.$store.dispatch("Users/fetchUsers", refresh);
    },
  },
  computed: {
    isBarLoading(): string {
      return this.$store.getters["HeaderLoadingBar/isBarLoading"];
    },
    getUser(): User {
      return this.$store.getters["User/getUser"];
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/scss/custom-bootstrap.scss";
$darkenAmount: 10;

header {
  font-size: 14px;
  width: 100%;
  // border-bottom: 1px solid darken($light, 20);
  -webkit-box-shadow: 0px 5px 5px darken($light, $darkenAmount);
  -moz-box-shadow: 0px 5px 5px darken($light, $darkenAmount);
  box-shadow: 0px 5px 5px darken($light, $darkenAmount);

  img {
    max-height: 60px;
  }
}

.header-border-gradient {
  height: 5px;
  margin-bottom: 0;
  border-radius: 0;
  background: linear-gradient(to left, #00beff, #003ca0);
}

.no-loading {
  cursor: auto;
}

.dropdown-link {
  color: #212529;
  text-decoration: none;
}

.nav-masthead {
  height: 100%;
}

.nav-link {
  font-weight: 700;
  color: #005fc1;
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
}

.router-link-active {
  color: #005fc1;
  border-bottom-color: #005fc1;
}
</style>
