<template>
  <footer class="bg-light p-sm-2 p-md-3 p-lg-4 p-lg-5">
    <b-row class="no-gutters justify-content-center align-items-center">
      <b-col
        class="d-flex flex-sm-row flex-column justify-content-center align-items-center"
        cols="12"
      >
        <a
          class="p-sm-0 p-3 mr-sm-4"
          href="https://www.ammeraalbeltech.com/ch/"
        >
          <img src="@/assets/images/Ammeraal_Logo_RGB.png" />
        </a>
        <address class="text-center">
          Ammeraal Beltech AG,<br />
          Buechstrasse 37, 8645 Rapperswil-Jona, Switzerland<br />
          <a href="mailto:ch-jon-rapplon@ammega.com"
            >ch-jon-rapplon@ammega.com</a
          ><br />
          <small>Member of Ammega Group.</small>
          <br />
          <small>v{{ pkgVersion }}</small>
        </address>
      </b-col>
    </b-row>
  </footer>
</template>

<script lang="ts">
import pkg from "../../../package.json";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    pkgVersion() {
      return pkg.version;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/scss/custom-bootstrap.scss";
$darkenAmount: 10;

footer {
  width: 100%;
  border-top: 1px solid darken($light, 20);
  -webkit-box-shadow: inset 0px 5px 5px darken($light, $darkenAmount);
  -moz-box-shadow: inset 0px 5px 5px darken($light, $darkenAmount);
  box-shadow: inset 0px 5px 5px darken($light, $darkenAmount);

  address {
    margin-bottom: 0;
  }

  img {
    max-height: 60px;
  }
}
</style>
