import {
  catchError,
  urlPrefix,
  config,
  axios,
  downloadBeltDocument,
  getCalculationQuery,
} from "./base-api";

import { BaseData } from "@/model/tangential-belt/base-data";
import { KeyValueObject } from "@/model/key-value-object";
import { TangentialBelt } from "@/model/tangential-belt/tangential-belt";
import { TangentialBeltReport } from "@/model/tangential-belt/tangential-belt-report";

const urlBaseData = `${urlPrefix}/tangential-belt`;

export default {
  async baseData(): Promise<BaseData> {
    return await axios
      .get(`${urlBaseData}/base-data`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async initialCalculate(): Promise<TangentialBelt> {
    return await axios
      .get(`${urlBaseData}/calculate/initial`, config)
      .then((response) => response.data)
      .catch(catchError);
  },
  async calculate(
    params: KeyValueObject | undefined,
    beltId: string | undefined,
    projectId: number | undefined,
  ): Promise<TangentialBelt> {
    return await axios
      .post(
        `${urlBaseData}/calculate${
          beltId || projectId
            ? "?" + getCalculationQuery(beltId, projectId)
            : ""
        }`,
        params,
        config,
      )
      .then((response) => response.data)
      .catch(catchError);
  },
  async report(reportData: TangentialBeltReport) {
    return await axios
      .post(`${urlBaseData}/report`, reportData, {
        responseType: "arraybuffer",
      })
      .then((response) => downloadBeltDocument(response, "tangential-belt"))
      .catch(catchError);
  },
};
