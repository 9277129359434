import store from "..";

export const errorNotify = (error: string) => {
  store.dispatch("Notification/error", error, { root: true });
};

export const warningNotify = (error: string) => {
  store.dispatch("Notification/warning", error, { root: true });
};

export const successNotify = (error: string) => {
  store.dispatch("Notification/success", error, { root: true });
};

export const startLoadingBar = () => {
  store.dispatch("HeaderLoadingBar/startLoading", undefined, {
    root: true,
  });
};

export const endLoadingBar = () => {
  store.dispatch("HeaderLoadingBar/stopLoad", undefined, {
    root: true,
  });
};
