import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb709e66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "site-spinner-container d-flex flex-row justify-content-between align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_spinner, {
      variant: "primary",
      label: _ctx.$t('Loading') + '...'
    }, null, 8, ["label"])
  ]))
}